<header *ngIf="user$|async as user">
  <div class="setup mt-lg-5">
    <div class="row">
      <div class="col-lg-12 col-md-6 col-sm-12 col-12">
        <div class="profile_img_section">
          <img [src]="img" class="ellipse" alt="" (click)="imageUpload.click()"
            [routerLink]="['./profile']">
            <img src="../../../assets/image/loading-4.gif" [ngClass]="{'d-flex': isUploading === true }" class="loading_stuoo" alt="">
          <img (click)="imageUpload.click()" src="../../../assets/image/button.png" alt="" class="img_mod_side">
          <input id="file-input" (change)="fileChange($event)" type="file" class="d-none" #imageUpload />
        </div>
        <p class="rite username " style="font-weight:bold;">{{ user.first_name }} {{ user.last_name }}</p>
      </div>
      <div class="col-lg-12 col-md-6 col-sm-12 col-12">
        <div class="d-flex flex-md-column align-content-md-center justify-content-md-center increase-width"
          style="height: 100%;">
          <ul class="ul_list mb-md-0">
            <li class="nav_inks_i">
              <a class="link" [routerLink]="['./profile']" routerLinkActive="active" ><span class="nav_inks_mo">
                  <img src="../../../assets/image/main/user_icon.png" alt="">
                </span><span class="nav_inks_moss"><span class="nav_lin">Profile</span><img class="nav_mdk"
                    src="../../../assets/image/left_arrow.png" alt=""></span></a>
            </li>
            <li class="nav_inks_i">
              <a class="link" [routerLink]="['./list']" routerLinkActive="active"><span class="nav_inks_mo">
                  <img src="../../../assets/image/main/fluent_hat-graduation-20-regular.png" alt="">
                </span><span class="nav_inks_moss"><span class="nav_lin">Courses</span><img class="nav_mdk"
                    src="../../../assets/image/left_arrow.png" alt=""></span></a>
            </li>
            <li class="nav_inks_i">
              <a class="link" [routerLink]="['./attendance']" routerLinkActive="active"><span class="nav_inks_mo " id="add_ps">
                  <img src="../../../assets/image/main/checkmark.png" alt="">
                </span><span class="nav_inks_moss"><span class="nav_lin">Attendance</span><img class="nav_mdk"
                    src="../../../assets/image/left_arrow.png" alt=""></span></a>
            </li>
            <li class="nav_inks_i text-center logout">
              <a (click)="logout()"><img src="../../../assets/image/logout.png" alt=""><span class="pl-4">
                  Logout</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
  <div class="rectangle">
  </div>

</header>