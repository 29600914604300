<nav class="navbar ">
  <div class="container" style="margin-top: 10px">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
        aria-expanded="false" aria-controls="navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="#" style="margin-left: -130px;margin-top: -10px">
        <img style="width: 166px;height: 42px;" src="/assets/image/Final-Logo.png" alt="no image">
      </a>
    </div>
    <div id="navbar" class="navbar-collapse collapse">
      <ul class="nav navbar-nav vcenter">
        <li class="active" style="background: transparent;"><a href="#" style="font-size: 25px;">Course List</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right" style="margin-right: -100px">
        <li><a [routerLink]="['/dashboard/list']" style="margin-right: -100px">{{ username }}</a></li>
      </ul>
    </div>
    <!--/.nav-collapse -->
  </div>
</nav>


<main class="container" style="margin-left: 12%;">
  <div class="row" style="margin-right: -10%;" *ngFor="let course of courses$ |async">
    <a [routerLink]="['/classroom',course.course.id]" style="color: black">
      <div class="col-md col-xl">
        <div class="panel panel-default" style="height: 187px; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-2" style="padding: 0">
                <img class="img-responsive"
                  style="width: 150px;height: 150px; border-radius: 5px; left: calc(50% - 150px/2 - 472px);"
                  src="{{ getProfileImg(course.course.image) || 'https://images.techhive.com/images/article/2014/07/linux-tux-100360806-large.jpg'}}"
                  alt="class image" />
              </div>
              <div class="col-md-10">
                <p style="color: grey; font-weight: 500;"> Course </p>
                <h4 style="margin-top: -5px;">{{ course.course.name }}</h4>
                <div style="height:55px ">
                  <p>{{ course.course.description }}</p>
                </div>
                <ul class="nav nav-pills ">
                  <li *ngIf="course.topics?.length as l" style="color: grey;">Course Videos: <strong
                      style="color: black;">{{
                      l}} </strong> </li>
                  <li *ngIf="course.users?.length as len" style="color: grey;">Registered Students: <strong
                      style="color: black;">{{ len }}</strong></li>
                  <li style="color: grey;">Course dates: <strong style="color: black;">{{ course.course.start_date }} - {{
                      course.course.end_date }}</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</main>