export class UserProfile{
    course_study: string;
    are_you_a_student: string;
    graduation_date?: string;
    did_you_graduate:  string;
    occupation?: string;
    does_your_country_require_post_graduate: string;
    completed_post_graduate?: string;
    profile_stage?: string;
}
  