<main>
  <div *ngIf="error_message" class="alert alert-danger p-3 mb-5 text-center animate__animated animate__shakeX"
    role="alert">
    {{ error_message }}
  </div>
  <div class="rectangle">
    <div class="first_form">
      <div class="form-fo" *ngIf="next_stage == 'stage1'">
        <div
          class="d-flex pt-lg-4 flex-row justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xm-end  align-content-lg-start check-mar">
          <img src="../../../assets/image/che.png" alt="" class="chec">
        </div>
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="first-f" novalidate>
          <div class="mt-5">
            <label class="form-label" for="course_study"><span class="numbering">1.</span> Course of study in the
              University:</label>
            <input class="form-control" formControlName="course_study" placeholder="Course of study" id="course_study">
          </div>
          <div class="mt-5">
            <label for="" class="form-label"><span class="numbering">2.</span>Are you a student? <span
                *ngIf="isStudent == true && isStudentSelected == true" class="response_yes"> <img
                  src="../../../assets/image/yes-check.png" class="yes-check" alt="" srcset=""> Yes</span> <span
                *ngIf="isStudent == false && isStudentSelected == true" class="response_no"><img
                  src="../../../assets/image/cancel.png" class="no-check" alt="" srcset=""> <span>
                  No</span></span></label>
            <div class="d-flex flex-row anser-yes-no btn_sek" *ngIf="isStudentSelected == false">
              <input class="form-control btn_select" type="button" (click)="selectIsStudent(true)" value="Yes">
              <input class="form-control btn_select" type="button" (click)="selectIsStudent(false)" value="No">
            </div>
            <div *ngIf="isStudent == true" class="d-flex flex-lg-row flex-md-row flex-sm-column are_you_a_student">
              <input class="form-control" formControlName="course_study" type="text" placeholder="Course of study"
                id="course_study">
              <input class="form-control" formControlName="graduation_date" type="text" onfocus="(this.type='date')"
                placeholder="Expected Graduation date" id="course_study">
              <img src="../../../assets/image/Group.png" alt="" (click)="resetStudentSelection()" class="image_back_b">
            </div>
          </div>
          <div class="mt-5">
            <label class="form-label" for=""><span class="numbering">3.</span>Did you graduate? <span
                *ngIf="isGraduate == true && isOccupation == true" class="response_yes"> <img
                  src="../../../assets/image/yes-check.png" class="yes-check" alt="" srcset=""> <span> Yes</span></span>
              <span *ngIf="isGraduate == false && isOccupation == true" class="response_no"><img
                  src="../../../assets/image/cancel.png" class="no-check" alt="" srcset=""> <span>
                  No</span></span></label>
            <div *ngIf="isOccupation == false" class="d-flex anser-yes-no flex-row  btn_sek">
              <input class="form-control btn_select" type="button" (click)="selectIsGraduate(true)" value="Yes">
              <input class="form-control btn_select" type="button" (click)="selectIsGraduate(false)" value="No">
            </div>
            <div *ngIf="isGraduate == false && isOccupation == true" class="d-flex flex-row">
              <input class="form-control" formControlName="occupation" type="text" placeholder="Current Occupation"
                id="course_study_o">
              <img src="../../../assets/image/Group.png" alt="" (click)="resetGraduateSelection()" class="image_back_b">
            </div>
          </div>
          <div class="mt-5 mb-5">
            <label class="form-label" for=""><span class="numbering">4.</span>Does your country require post-graduation
              service? <span *ngIf="isPostgratuate == true && isPostgratuateSectionShowing == false"
                class="response_yes"><img src="../../../assets/image/yes-check.png" class="yes-check" alt="" srcset="">
                <span> Yes</span></span> <span *ngIf="isPostgratuate == false && isPostgratuateSectionShowing == false"
                class="response_no"><img src="../../../assets/image/cancel.png" class="no-check" alt="" srcset="">
                <span> No</span></span></label>
            <div *ngIf="isPostgratuateSectionShowing" class="d-flex flex-row anser-yes-no btn_sek">
              <input class="form-control btn_select" type="button" (click)="selectIspostGraduate(true)" value="Yes">
              <input class="form-control btn_select" type="button" (click)="selectIspostGraduate(false)" value="No">
            </div>
            <div *ngIf="isPostgratuate" class="d-flex flex-row mt-3 smid">
              <img src="../../../assets/image/lshape.png" srcset="" alt="noimage" class="vect_img">
              <div>
                <label for="" class="mt-5">Have you completed ? <span
                    *ngIf="CompletepostGraduate == true && CompletepostGraduateSectionShowing == false"
                    class="response_yes"><img src="../../../assets/image/yes-check.png" class="yes-check" alt=""
                      srcset=""> <span> Yes</span></span> <span
                    *ngIf="CompletepostGraduateSectionShowing == false && CompletepostGraduate == false"
                    class="response_no"><img src="../../../assets/image/cancel.png" class="no-check" alt="" srcset="">
                    <span> No</span></span></label>
                <div *ngIf="CompletepostGraduateSectionShowing" class="d-flex flex-row">
                  <input class="form-control btn_select" type="button" (click)="selectCompletepostGraduate(true)"
                    value="Yes">
                  <input class="form-control btn_select" type="button" (click)="selectCompletepostGraduate(false)"
                    value="No">
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 justify-content-center">
            <button type="submit" *ngIf="profileForm.valid"
              class="btn btn-primary text-center text-capitalize text-white" id="next_step_btn">Next
              Step <img src="../../../assets/image/ar.png" alt="" srcset=""></button>
          </div>
        </form>
      </div>
      <!-- form three -->

      <div *ngIf="next_stage == 'stage2'" class="stage2 animate__animated animate__zoomIn">
        <div
          class="d-flex pt-lg-4 flex-row justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xm-end  align-content-lg-start check-mar">
          <img src="../../../assets/image/che.png" alt="" class="chec">
        </div>
        <form [formGroup]="levelInterestForm" (ngSubmit)="secondForm()" novalidate>
          <h6 class="form-interest-title">Score your level of interest in these job titles:</h6>
          <div class="small-row" id="small-row">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="first-side">
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Cybersecurity</p>
                      <p class="range_value"><span>{{ level_of_interest['cybersecurity'] || 1 }} </span>/<span>10</span>
                      </p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="1" formControlName="cybersecurity"
                        (change)="onInputChange($event, 0, 'cybersecurity')"></mat-slider>
                    </div>
                  </div>
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Developer</p>
                      <p class="range_value"><span>{{ level_of_interest['developer'] || 1 }}</span>/<span>10</span></p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="5" formControlName="developer"
                        (change)="onInputChange($event, 1, 'developer' )"></mat-slider>
                    </div>
                  </div>
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">DevOps</p>
                      <p class="range_value"><span>{{ level_of_interest['devops'] || 1 }}</span>/<span>10</span></p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="1" formControlName="devops"
                        (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                    </div>
                  </div>
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Designer</p>
                      <p class="range_value"><span>{{ level_of_interest['designer'] || 1 }}</span>/<span>10</span></p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="3" formControlName="designer"
                        (change)="onInputChange($event, 3, 'designer')"></mat-slider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="second-side">
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Project Manager</p>
                      <p class="range_value"><span>{{ level_of_interest['project_manager'] || 1
                          }}</span>/<span>10</span>
                      </p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="1" formControlName="project_manager"
                        (change)="onInputChange($event, 4, 'project_manager')"></mat-slider>
                    </div>
                  </div>
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Product Manager</p>
                      <p class="range_value"><span>{{ level_of_interest['product_manager'] || 1
                          }}</span>/<span>10</span>
                      </p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="1" formControlName="product_manager"
                        (change)="onInputChange($event, 5, 'product_manager')"></mat-slider>
                    </div>
                  </div>
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Marketer</p>
                      <p class="range_value"><span>{{ level_of_interest['marketer'] || 1 }}</span>/<span>10</span></p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="1" formControlName="marketer"
                        (change)="onInputChange($event, 6, 'marketer')"></mat-slider>
                    </div>
                  </div>
                  <div class="interset_section mt-3">
                    <div class="d-flex flex-row justify-content-between top-text">
                      <p class="label_title_p">Writer</p>
                      <p class="range_value"><span>{{ level_of_interest['writer'] || 1 }}</span>/<span>10</span></p>
                    </div>
                    <div class="range-slider">
                      <mat-slider min="0" max="10" step="1" value="3" formControlName="writer"
                        (change)="onInputChange($event, 7, 'writer')"></mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="warning-section mb-5">
            <!-- <p class="warning-section-text"><span>*</span>You cannot pick a number twice</p> -->
            <div *ngFor="let interes of allrates | keyvalue">
              <div class="row">
                <div class="col-lg-3 col-sm-4 col-6">
                  <span>{{ interes.value.name }} </span>
                </div>
                <div class="col-lg-3 col-sm-6 col-3">
                  <span class="text-right"> {{ interes.value.rate }}</span>
                </div>
              </div>

            </div>
          </div>
          <div class="d-flex flex-row mt-5 justify-content-center">
            <button type="submit" class="btn btn-primary text-center text-capitalize text-white final-submittion"
              id="next_step_btn_done">
              Done<em></em></button>
          </div>
        </form>
      </div>

      <form [formGroup]="profile" novalidate (ngSubmit)="updateInfo()" *ngIf="next_stage == 'completed'">
        <div class="profile-logo">
          <span class="user-profile-icon">
            <img src="../../../assets/image/main/user_icon.png" alt="">
          </span> Your Profile
        </div>
        <div class="bio-info animate__animated animate__slideInDown">
          <div
            class="d-flex flex-row justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xm-end  align-content-lg-start check-mar">
            <img src="../../../assets/image/che.png" alt="" class="chec">
          </div>
          <div class="user-school-s-f">
            <div class="user-bios email-bois">
              <label class="form-label" for="">
                First Name:
              </label>
              <div class="bios">
                <input type="text" class="form-control" formControlName="Firstname" id="profile-input-f"
                  placeholder="First Name">
                <img src="../../../assets/image/edit.png" alt="" (click)="makeeditable()">
              </div>
            </div>
            <div class="user-bios">
              <label class="form-label" for="">
                Last Name:
              </label>
              <div class="bios">
                <input type="text" class="form-control" formControlName="Lastname" id="profile-input-l"
                  placeholder="Last Name">
                <img src="../../../assets/image/edit.png" alt="" (click)="makeeditable()">
              </div>
            </div>
            <div class="user-bios">
              <label class="form-label" for="">
                Email:
              </label>
              <div>
                <input type="text" class="form-control" formControlName="email" id="profile-input" id="profile-input-e"
                  placeholder="Email">
              </div>
            </div>

          </div>
          <div class="d-flex flex-row mt-4 justify-content-center" *ngIf="!isdisabled">
            <button type="submit" class="btn btn-primary text-center text-capitalize text-white"
              id="next_step_btn-e">Save</button>
          </div>
        </div>
        <div class="user-school animate__animated animate__zoomIn">
          <div
            class="d-flex flex-row justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xm-end  align-content-lg-start check-mar">
            <img src="../../../assets/image/che.png" alt="" class="chec">
          </div>
          <div class="user-school-s-f">
            <div class="user-bios" id="course_of_study">
              <label class="form-label" for="">
                Course of study in school:
              </label>
              <div>
                <input type="text" class="form-control" id="profile-input-cc" formControlName="course_of_study"
                  placeholder="English Language">
              </div>
            </div>
            <div class="user-bios">
              <label class="form-label" for="">
                I am a student:
              </label>
              <div>
                <input type="text" class="form-control" id="profile-input-dd" formControlName="I_am_a_student"
                  placeholder="Writer">
              </div>
            </div>
            <div class="user-bios">
              <label class="form-label" for="">
                Graduation date:
              </label>
              <div>
                <input type="text" class="form-control" id="profile-input-ee" formControlName="Graduation_date"
                  placeholder="October 4th, 2021">
              </div>
            </div>

          </div>
        </div>
        <div class="user-bio-int animate__animated animate__slideInUp">
          <div class="my-interest">
            <p>My Interests:</p>
          </div>
          <div class="user-he">
            <div class="user-bio-interest">

              <div class="user-interset_section">
                <div class="d-flex flex-row justify-content-between top-text px-2 mx-0">
                  <p class="label_title_p">Devops</p>
                  <p class="range_value"><span class="sapnvale">{{ devops.value }}</span><span
                      class="sapnvale">/10</span></p>
                </div>
                <div class="range-slider">
                  <mat-slider min="1" max="10" step="1" value="6" #devops formControlName="devops"
                    (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                </div>
              </div>
              <div class="user-interset_section">
                <div class="d-flex flex-row justify-content-between top-text px-2 mx-0">
                  <p class="label_title_p">Writer</p>
                  <p class="range_value"><span class="sapnvale">{{ writer.value }}</span><span
                      class="sapnvale">/10</span></p>
                </div>
                <div class="range-slider">
                  <mat-slider min="1" max="10" step="1" value="4" #writer formControlName="writer"
                    (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                </div>
              </div>
            </div>
            <div class="user-bio-interest">
              <div class="user-interset_section">
                <div class="d-flex flex-row justify-content-between top-text px-2 mx-0">
                  <p class="label_title_p">Designer</p>
                  <p class="range_value"><span class="sapnvale">{{ designer.value }}</span><span
                      class="sapnvale">/10</span></p>
                </div>
                <div class="range-slider">
                  <mat-slider min="1" max="10" step="1" value="6" #designer formControlName="designer"
                    (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                </div>
              </div>
              <div class="user-interset_section">
                <div class="d-flex flex-row justify-content-between top-text px-2 mx-0">
                  <p class="label_title_p">Marketer</p>
                  <p class="range_value"><span class="sapnvale">{{ marketer.value }}</span><span
                      class="sapnvale">/10</span></p>
                </div>
                <div class="range-slider">
                  <mat-slider min="1" max="10" step="1" value="4" #marketer formControlName="marketer"
                    (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                </div>
              </div>
            </div>
            <div class="user-bio-interest">
              <div class="user-interset_section">
                <div class="d-flex flex-row justify-content-between top-text px-2 mx-0">
                  <p class="label_title_p">Cybersecurity</p>
                  <p class="range_value"><span class="sapnvale">{{ cybersecurity.value }}</span><span
                      class="sapnvale">/10</span></p>
                </div>
                <div class="range-slider">
                  <mat-slider min="1" max="10" step="1" value="6" #cybersecurity formControlName="cybersecurity"
                    (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                </div>
              </div>
              <div class="user-interset_section">
                <div class="d-flex flex-row justify-content-between top-text px-2 mx-0">
                  <p class="label_title_p">Product Manager</p>
                  <p class="range_value"><span class="sapnvale">{{ product_manager.value }}</span><span
                      class="sapnvale">/10</span></p>
                </div>
                <div class="range-slider">
                  <mat-slider min="1" max="10" step="1" value="4" #product_manager formControlName="product_manager"
                    (change)="onInputChange($event, 2, 'devops')"></mat-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
