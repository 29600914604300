<div class="chat-item" *ngIf="message.username === current_user && message.the_type !== 'date'">
    <div class="my-message">
        <div class="head">
            <span class="datetime">{{
                chatService.formatTime(message.timestamp)
              }}</span>
            <span class="myname">You:</span>
        </div>
        <div class="d-flex flex-column">
            <div>
                <span class="message-body" *ngIf="message.the_type === 'plain' || message.the_type === undefined "
            [innerHTML]="message.content">
            </span>
            </div>
            <div *ngIf="message.reaction" class="reactions">
                <span class="reaction">{{ getreaction(message.reaction) }}</span>
            </div>
        </div>
        <span class="message-img" *ngIf="message.the_type === 'file' || message.the_type === undefined ">
            <a [href]="message.content" download="{{ message.username }}'s_upload">
                <img [src]="message.content" alt="" class="imag-upload"></a>
                <span class="img-label">
                    <img src="../../../assets/image/attach-icon.svg" alt="">
                </span>
                <!-- <i class="fas fa-external-link-alt"></i> -->
            <i class="img-label-text">Attached image</i>
        </span>
    </div>
</div>
<!-- 

<div class="chat-item" *ngIf="message.username === current_user && message.the_type !== 'date'">
     <span *ngIf="message.edited">
        <i-feather name="edit-2" class="edit-2"></i-feather>
    </span>
    <div class="head">
         <button (click)="editMessage()" class="edit-but">
            <i-feather name="edit" class="edit"></i-feather>
        </button>
        <img src="../../../assets/image/qoute-icon.png" alt="" (click)="qoute()"
            *ngIf="message.the_type === 'plain' || message.the_type === undefined ">
        <i>{{ chatService.formatTime(message.timestamp) }}</i>
        <h4>{{message.username}}</h4> 
      
    </div>
    <div class="my">
        <div class="message">
            <p class="content">
                <app-qoute-message [message]="message.qoute_message" *ngIf="message.qoute_message"></app-qoute-message>
                <span *ngIf="message.the_type === 'plain' || message.the_type === undefined "
                    [innerHTML]="message.content">
                </span>
                <span *ngIf="message.the_type === 'file' ">
                    <i> Uploaded a file: </i>
                    <a [href]="message.content" download="{{ message.username }}'s_upload" class="download-icon">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </span>
            </p>
            <div class="mychat-thumb">
                <span *ngIf="message.the_type === 'image' ">
                    <a [href]="message.content" download="{{ message.username }}'s_upload" class="download-icon anorhe">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </span>
                <app-image-lightbox *ngIf="message.the_type === 'image' "
                    [imageUrl]="chatService.formatProfileImg(message.content)">
                </app-image-lightbox>
            </div>
        </div>
         <div class="image" *ngIf="user$|async as i">
            <img *ngIf="message.profile_img" [src]="message.profile_img" />
            <img *ngIf="message.user_profile_img" [src]="message.user_profile_img" />
        </div>
    </div>
</div> -->