import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute,  Router} from '@angular/router';
import {environment} from "../environments/environment";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public message;
  public login_username;
  public login_password;
  public createuser_email;
  public createuser_password;
  public createuser_username;
  public username;
  public id;
  public users;
  public updatedImgUrl = ''
  // private defaultProfileImg:string = 'https://res.cloudinary.com/louiseyoma/image/upload/v1546701687/profile_pic.png';
  public isLoginRan : boolean = false;
  private headers: HttpHeaders = new HttpHeaders();


  public httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };
  public authOptions;
  private required:boolean = false;
  private uploaded:boolean = true;


  constructor(private http: HttpClient, private router: Router,
              private route: ActivatedRoute,location:Location,
             ) {

  }

  getUsername(){
    return this.http.get(environment.API_URL + "api/users/get_username")
  }


  /*
    Nipun's change, this function checks where the user is currently logged in or not. Since it is being executed multiple times 
    in a single session, the this.isLoginRan variable is used to overcome this issue 
  */
  islogin() {
    var token = sessionStorage.getItem("token");
    if(token != "" && token != null && !this.isLoginRan){
      this.isLoginRan = true;
      return true
    }else{
      this.isLoginRan = false;
      return false;
    }
  }

  logout() {
    sessionStorage.clear()
    window.location.replace(environment.API_URL)
  }

  addcourse(){
    sessionStorage.getItem('username')
    window.location.replace(environment.API_URL + 'groupCourse/')
  }

  /*
    Nipun's change, changed this method to return the observable of resulting HTTP request, moved subscribe() logic to auth-guard.ts
  */
  sessionSet(token:string,group_id:string) {
    console.log("data service called");
    return this.http.post(environment.API_URL + 'sso_api/confirm_key/' + group_id, JSON.stringify({'token':token}),this.httpOptions)
  }

  /* 
    Nipun's change, this function was changed to only return true so that the end user is able to post messages in the chatroom 
    even when they do not have a profile image(in which case, a default profile image will be assigned to them )
  */
  profileImgIsSet(){
    return true;
    // return sessionStorage.getItem('profile_img')==this.defaultProfileImg?
    //   false:true;
  }

  isInstructor(){
    return sessionStorage.getItem('is_instructor')=='true'?
    true:false;
  }

}

