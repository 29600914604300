<div class="row">
  <div class="col-lg-10">
    <div
      class="card card-default medium"
      id="note-section"
      *ngIf="note$ | async as note; else other_content"
    >
      <div class="card-title">
        <span><img src="../../../assets/image/pay_btn.svg" alt="" /></span
        ><span class="course-note">{{ note.Topic }}</span>
      </div>
      <div class="card-content" style="overflow: auto" id="chat_div_space">
        <div [innerHTML]="note.Detail"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #other_content>
  <div class="card medium">
    <div class="card-title" style="padding-left: 30px">No notes available</div>
  </div>
</ng-template>
