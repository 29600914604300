import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { DashboardRoutingModule } from './dashboard-routing.module';
import { HeaderComponent } from './header/header.component';
import { ContentComponent } from './content/content.component';
import { ProfileComponent } from './profile/profile.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { StudentsComponent } from './students/students.component';
import { SettingsComponent } from './settings/settings.component';
import {ListComponent} from './list/list.component';
import { AlertModule } from './../course/_alert/alert.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { MatSliderModule} from '@angular/material/slider';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { NavigationComponent } from './navigation/navigation.component';


@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatSliderModule,
    AlertModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  declarations: [HeaderComponent, ContentComponent, ProfileComponent, AttendanceComponent,StudentsComponent, SettingsComponent,ListComponent, NavigationComponent],//StudentsComponent,
  bootstrap: [ HeaderComponent ]
})
export class DashboardModule { }
