<div class="navbar">
  <nav>
    <div class="nav-wrapper">
      <div class="container-fluid">
        <div class="row">

          <div class="col l3 m3 s3">
            <a class="brand-logo"><img src="/assets/image/Final-Logo.png" alt="nav-logo" class="logo"><span class="logo-text">ClassRoom</span></a>
          </div>

          <div class="col l3 m3 s3">

          </div>

            <div class="col l6 m6 s6">
              <ul class="right">

              </ul>
            </div>
        </div>
      </div>
    </div>
  </nav>
</div>

    <div class="container">
<div class="row">
        <div class=" col s6 offset-s3 center-align ">
            <h4 class="center-align"> Grant permission to record 10 seconds verification video </h4>
            <em class="center-align"> Class would be opened after verification </em>
        </div>
    </div>

</div>

