export class CourseTopicModel {
  id:number;
  topic: string;
  video:string;
  tasks=[];
  note:string;
  constructor(){
     // This is empty
  }
}

export class EditTopicModel {
  id:number;
  topic: string;
  video:string;
  constructor(){
    // This is empty
  }
}
