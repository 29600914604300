import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../share/api.service";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: 'app-topic-video',
  templateUrl: './topic-video.component.html',
  styleUrls: ['./topic-video.component.css']

})
export class TopicVideoComponent implements OnInit {

  route:any ;
  public topic:any;
  public data$:any;
  constructor(private apiService:ApiService,route:ActivatedRoute,private router:Router) {
    this.route=route
  }



  ngOnInit() {
    this.data$ = this.apiService.data$

  }

}
