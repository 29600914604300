import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-classroom',
  templateUrl: './main-classroom.component.html',
  styleUrls: ['./main-classroom.component.css']
})
export class MainClassroomComponent implements OnInit {

  constructor() {
    // Nothing here
  }

  ngOnInit() {
    // Nothing here
  }

}
