<app-admin-navigation></app-admin-navigation>
<div class="container mt-5">
   <div class="mt-lg-5 mb-lg-5 p-lg-3"></div>
   <div class="row justify-3-md-center reverse-my">
      <div class="col-lg-3 col-md-12 col-sm-12 col-12 order-md-2 order-sm-2 order-lg-1 mt-lg-0 mt-md-5 px-0">
         <app-header class="has-fixed-sidenav"></app-header>
      </div>
      <div class="col-lg-7 col-md-12 col-sm-12 col-12 order-md-1 order-sm-1 order-lg-2 offset-lg-1 mt-lg-5 mt-md-4 px-0">
         <div>
            <router-outlet></router-outlet>
         </div>
      </div>
   </div>
</div>
