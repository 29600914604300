import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatVideoModule } from 'mat-video';
import { CourseComponent } from './course.component';
import { TopicChatComponent } from './topic-chat.component';
import { CourseRoutingModule } from './course-routing.module';
import { TopicLabComponent } from './topic-lab/topic-lab.component';
import { TopicNotesComponent } from './topic-notes/topic-notes.component';
import { TopicVideoComponent } from './topic-video/topic-video.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SafePipe } from "../share/safe.pipe";
import { OrderModule } from "ngx-order-pipe";
import { SharedModule } from "../../shared/shared.module";
import { VerificationComponent } from './verification/verification.component';
import { AuthGuard } from "../auth.guard";
import { VerificationGuard } from "../verification.guard";
import { CourseListComponent } from './course-list/course-list.component';
import { AlertModule } from './_alert/alert.module';
import { LightboxModule } from './_lightbox/lightbox.module';
import { ImageLightboxComponent } from './_image-lightbox/image-lightbox.component';
import { InfiniteScrollComponent } from './_infinite-scroll/Infinite-scroll.component';
//import { MentionModule } from './_mention/mention.module';
import { MentionModule } from 'angular-mentions';
import { OthersChatComponent } from './others-chat/others-chat.component';
import { MyChatComponent } from './my-chat/my-chat.component';
import { QouteMessageComponent } from './qoute-message/qoute-message.component';
import { IconsModule } from '../icons/icons.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EditMessageComponent } from './edit-message/edit-message.component';
import { MyOptionsComponent } from './my-options/my-options.component';
import { MainChatComponent } from './main-chat/main-chat.component';
import { PrivateChatComponent } from './private-chat/private-chat.component';
import { OverlayModule } from '@angular/cdk/overlay';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    MatVideoModule,
    BrowserModule,
    CommonModule,
    CourseRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    OrderModule,
    SharedModule,
    AlertModule,
    LightboxModule,
    MentionModule,
    IconsModule,
    PickerModule,
    OverlayModule
  ],
  declarations: [
    CourseComponent,
    TopicChatComponent,
    TopicLabComponent,
    TopicNotesComponent,
    TopicVideoComponent,
    SafePipe,
    VerificationComponent,
    CourseListComponent,
    ImageLightboxComponent,
    InfiniteScrollComponent,
    OthersChatComponent,
    MyChatComponent,
    QouteMessageComponent,
    EditMessageComponent,
    MyOptionsComponent,
    MainChatComponent,
    PrivateChatComponent,
  ],
  providers: [AuthGuard, VerificationGuard],
  bootstrap: [CourseComponent]
})
export class CourseModule {

}
