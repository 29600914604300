import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../share/api.service";
import { UserModel } from "../../share/user-model";
import { Observable, forkJoin } from "rxjs/index";
import { map } from "rxjs/operators"

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {

  public students: Observable<UserModel[]>;
  public user = new UserModel();
  public attendances
  public attendance
  public last_login_arr: Observable<any>[] = []
  public showSpinner = false
  public deleteStudents = []
  constructor(private apiService: ApiService) {
    this.students = apiService.getMembers(sessionStorage.getItem('active_group'))
  }

  ngOnInit() {
    // This is intentional
  }

  deleteByDate(date: HTMLInputElement) {
    this.showSpinner = true
    let students_id = this.getStudentsId()
    students_id.subscribe(res => {
      res.forEach(val1 => {
        this.attendance = this.apiService.getUserAttendance(sessionStorage.getItem('active_group'), val1)
        const last_login = this.attendance.pipe(
          map(att => { return [val1, att[0]] })
        )
        this.last_login_arr.push(last_login)
      })

      forkJoin(this.last_login_arr).subscribe((arr) => {
        arr.forEach(val2 => {
          // console.log(val2)
          if (val2[1]) {
            const last_login_date = new Date(val2[1].timestamp.slice(0, -10))
            // console.log(val2)
            if (date.valueAsDate >= last_login_date) {
              console.log('wil be deleted')
              this.apiService.deleteUser(sessionStorage.getItem('active_group'), val2[0]).subscribe()
            }
          }
          else {
            this.apiService.deleteUser(sessionStorage.getItem('active_group'), val2[0]).subscribe()
          }
        })
      },
        (error) => {
          console.error(error)
        },
        () => {
          this.showSpinner = false
          setTimeout(() => {
            alert('Students have been removed')
            window.location.replace(window.location.origin + "/admin/students")
          }, 1000)
        }
      )

    },
      (error) => {
        console.error(error)
      }
    )

  }

  deleteByDate2(date: HTMLInputElement) {
    this.showSpinner = true
    let students_id = this.getStudentsId()
    students_id.subscribe(res => {

      res.forEach(val1 => {
        this.attendance = this.apiService.getUserAttendance(sessionStorage.getItem('active_group'), val1.id)
        const last_login = this.attendance.pipe(
          map(att => { return [val1, att[0]] })
        )
        this.last_login_arr.push(last_login)

      })

      forkJoin(this.last_login_arr).subscribe((arr) => {
        arr.forEach(val2 => {
          // console.log(val2)
          if (val2[1]) {
            const last_login_date = new Date(val2[1].timestamp.slice(0, -10))
            // console.log(val2)
            if (date.valueAsDate >= last_login_date) {
              console.log('wil be deleted')
              this.deleteStudents.push(val2[0])
            }
          }
          else {
            this.deleteStudents.push(val2[0])
          }
        })
        console.log(this.deleteStudents)
        this.apiService.deleteUsersByLastLogin(sessionStorage.getItem('active_group'), this.deleteStudents)
          .subscribe(data => {
            console.log(data)
            this.showSpinner = false
            setTimeout(() => {
              alert('Students have been removed')
              window.location.replace(window.location.origin + "/admin/students")
            }, 1000)
          })
      }
      )


    },
      (error) => {
        // This is intentional
      },
      () => {
        // console.log("hi")
      }
    )

  }
  getStudentsId() {
    return this.students.pipe(map(val => { return val.map(val1 => { return val1 }) }))
  }

}
