 <main>
    	<div class="section container">
  <div class="row">
    <div class="col s12 m4">
      <span class="settings-title">Configure Class </span>
        <p> Here you can set class specific settings</p>
    </div>
    <div class="col s12 m8">

      <div class="settings-group">
        <div class="setting">
          Video Verification

          <div class="switch right">
            <label>
              <input  [(ngModel)]="group.video_required" name="group.video_required" [ngModelOptions]="{standalone: true}" (change)="toggleVideo()"  type="checkbox">
              <span class="lever"></span>
            </label>
          </div>
        </div>

      </div>

    </div>
  </div>
        </div>
 </main>
