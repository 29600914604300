<div class="row">
  <!-- <div class="col-lg-4">
    <div class="chat-left-side">
      <div class="chat-left-side-body">
        <em class="material-icons search">search</em>
        <input
          type="text"
          class="search-input"
          name=""
          placeholder="Search user"
          id=""
        />
      </div>
      <div class="">
        <ul class="">
          <li>
            <a [routerLink]="['./topic-chat']">
              <div class="general_chat">
                <span class="general_chat_a">
                  <img src="../../assets/image/chat-icon.svg" alt="" />
                  <span class="general_chat_a_text">General Chat</span>
                </span>
                <em class="material-icons forward-play-icon"
                  >arrow_forward_ios</em
                >
              </div>
            </a>
          </li>
          <li class="chat-left-users">
            <a href=""
              ><div class="inner_chat">
                <img src="../../assets/image/38491485.png" alt="" srcset="" />
                <h1>
                  Mary S. <br /><span class="last_message"
                    >Working with you ...</span
                  >
                </h1>
                <em class="material-icons forward-play-icon"
                  >arrow_forward_ios</em
                >
              </div></a
            >
          </li>

          <li><a href=""></a></li>
          <li><a href=""></a></li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="col-lg-12">
    <!-- Chat View -->
    <mat-card class="card medium" style="display: flex">
      <div class="participants">
        <div class="participant-drop">
          <div class="participant-drop-sen">
            <img src="../../assets/image/ant-design.svg" alt="" />
            <span class="participant-drop-text">List of participants</span>
          </div>
          <span class="participant-btn" (click)="showparticipants()"><img src="../../assets/image/arrow_down.svg" alt="" [class.participant-icon-img]="showparticipant" /></span>
        </div>
        <div [class.participant-menu]="showparticipant" >
          <div class="user-list-participant">
            <div class="row">
              <div class="col-6" *ngFor="let member of classparticipant">
                <div class="user_padrs">
                  <img
                    [src]="member.user.profile_img"
                    alt=""
                    class="user_participants"
                  />
                  <span>{{member.user.first_name}} {{member.user.last_name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" style="overflow: auto" id="chat_div_space" #ChatSpace>
        <mat-card class="main-card">
          <mat-list class="chat-list" dense>
            <!-- Load More -->
            <infinite-scroll (scrolled)="getMessages()" *ngIf="offset_id != -1">
              <p class="loading-history" *ngIf="is_send_message == true" >Loading history...</p>
            </infinite-scroll>

            <div *ngFor="let message of messages; let i = index;">
              <!-- <div *ngIf="message.today_the_type === 'date'" class="chat-date">
                    <div>
                      <span class="chat-date-text">
                        {{formatDate(message.today)}}
                      </span>
                    </div>
                </div>
                <div *ngIf="message.the_type === 'date'" class="chat-date">
                  <div>
                    <span class="chat-date-text">
                      {{formatDate(message.content)}}
                    </span>
                  </div>
              </div> -->
              <!-- {{ formatDate(message.timestamp) }} messages?.length === 1-->
              <div *ngIf="messages?.length === 1" class="chat-date">
                <div>
                  <span class="chat-date-text"
                    >{{ formatDate(message.timestamp) }}</span
                  >
                </div>
              </div>
              <div *ngIf="messages?.length > 1">
                <div
                  *ngIf=" message.timestamp !== '---' && messages[i-1]?.timestamp !== undefined"
                >
                  <div
                    *ngIf="getformatDate(message.timestamp) !== getformatDate(messages[i-1]?.timestamp)"
                    class="chat-date"
                  >
                    <div>
                      <span class="chat-date-text"
                        >{{ formatDate(message.timestamp) }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              
              <app-others-chat
                [message]="message"
                (qouted)="qoute($event)"
              ></app-others-chat>
              <app-my-chat
                [message]="message"
                (qouted)="qoute($event)"
                (edit)="editMessage($event)"
              ></app-my-chat>
              <!-- <div class="user-joined">
                <small class="user-joined text-center">jerry has joined</small>
              </div> -->
            </div>
          </mat-list>
        </mat-card>
      </div>
      <div class="mat-cardss">
        <mat-card-footer >
          <div>
            <app-qoute-message
              *ngIf="qoute_message"
              [(message)]="qoute_message"
              [display_close]="true"
              (closed)="closed()"
            >
            </app-qoute-message>
          </div>
          <div>
            <app-edit-message
              *ngIf="edit_message"
              [display_close]="true"
              (closed)="closed()"
            >
            </app-edit-message>
          </div>
          <!-- is typin side  -->
  
          <div *ngIf="typingIndicator | async" class="typingIndicator">
            <span
              >{{who | async}}
              <div class="containerr">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </span>
          </div>
          <br />
          <div *ngIf="userstyping.size > 0">
            <span *ngIf="userstyping.size > 2" class="istyping">Several people are typing</span>
            <span *ngIf="userstyping.size == 2" class="istyping">{{ mergeset(userstyping) }} are typing</span>
            <span *ngIf="userstyping.size == 1" class="istyping">{{ userstyping.keys().next().value }} is typing</span>
          </div>
          <form class="form-group">

            <!-- <button
            type="button"
            class="toggle-emoji"
            (click)="toggleEmojiPicker()"
            >
            <i-feather name="smile"></i-feather>
          </button> -->
            
            <!-- <inputformControlName="message"  (keyup.enter)="sendMessage(chat_text,'plain')" class="form-control"  name="chat_text" [(ngModel)]="chat_text" placeholder="Type Your Message Here" maxlength="400"> -->
            <div class="input-text-area">
              <div class="image-upload">
                <label>
                  <!-- <i class="icon-attach material-icons">add</i> -->
                  <span class="material-icons" (click)="toggleEmojiPicker()">
                    sentiment_satisfied
                    </span>
                </label>
              </div>
              <textarea
              (ngModelChange)="isTyping($event)"
              (blur)="endTyping($event)"
              (keydown)="endTyping($event)"
              [ngClass]="{'inactive': !(touched | async)}"
              class="form-control type-input"
              name="chat_text"
              rows="1"
              #chat_input
              [(ngModel)]="chat_text"
              placeholder="Type a message"
              maxlength="400"
              [mention]="mention_users"
              [mentionConfig]="mentionConfig"
              id="chat_s"
              (focus)="onFocus()"
            ></textarea>
            <div class="image-upload">
              <label for="file-input">
                <span class="file_input_icon"><img src="../../assets/image/attach.svg" alt="" srcset="" /></span>
                <!-- <i class="icon-attach material-icons">add</i> -->
                <input
                  style="display: none"
                  id="file-input"
                  (change)="fileChange($event)"
                  type="file"
                />
              </label>
            </div>
            </div>
            <button
              class="send"
              (click)="sendMessage(chat_text,'plain')"
              id="submit"
            >
            <img class="send_icon" src="../../assets/image/Vector.svg" alt="">
              <span class="send_text">Send</span>
            </button>
          </form>
          <!-- <emoji-mart class="emoji-mart" set="emojione" (emojiSelect)="addEmoji($event)" *ngIf="showEmojiPicker" title="Pick your emoji…"></emoji-mart> -->
        </mat-card-footer>
      </div>
    </mat-card>
    <emoji-mart class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>

    <!-- <mat-card class="personal-chat">

    </mat-card> -->
  </div>
</div>


