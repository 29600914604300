export class ClassModel {
  id:number;
  course:string;
  description:string;
  name:string;
  users=[];
  topics=[];
  start_date:string;
  end_date:string;
  video_required:boolean;
  constructor(){
    // This is empty

  }

}
