<div class="course">
  <div class="course-top">
    <div class="course-top-title">Django Group Course</div>
    <div class="course-top-description">
      Diam, odio aliquet iaculis nisi, est urna diam etiam suspendisse.
      Pretium vel tellus neque faucibus tincidunt.
    </div>
  </div>
  <div class="course-bottom">
    <div class="course-b-progress">PROGRESS • 20%</div>
    <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
    <div fxLayout="row" class="c-b-status" fxLayoutAlign="space-evenly center">
      <div>
        <div class="c-b-status-title">
          STATUS
        </div>
        <div class="c-b-status-content">
          Ongoing
        </div>
      </div>
      <div>
        <div class="c-b-status-title">
          START DATE
        </div>
        <div class="c-b-status-content">
          05 March 2020
        </div>
      </div>
      <div>
        <div class="c-b-status-title">
          END DATE
        </div>
        <div class="c-b-status-content">
          27 April 2020
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex="1 0 auto">
        <button class="course-btn" fxLayout="row" fxLayoutAlign="center center" mat-raised-button><div>Go to classroom</div></button>
      </div>
      <div class="remove-course" fxFlex="0 1 auto">Remove Course</div>
    </div>
  </div>
</div>
