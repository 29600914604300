export const environment = {
    production: true,
    hmr       : false,
    host: 'https://linuxjobber.com',
    apiUrl: 'https://linuxjobber.com/api',
    API_URL:'https://linuxjobber.com/',
    WS_URL:'wss://cvkwvn72l3.execute-api.us-west-2.amazonaws.com/production',
    PUSH_AUDIO: 'https://opentogeneralpublic.s3.us-west-2.amazonaws.com/mixkit-correct-answer-tone-2870.wav',
    DEFAULT_PROFILE_IMG: "https://res.cloudinary.com/louiseyoma/image/upload/v1546701687/profile_pic.png"
};                                                                                                             

