 <main>
    <div class="rectangle">
      <div class="section container">
        <div class="row" >
          <div class="col s12 m4">
            <span class="settings-title">Students</span>
            <div class="row">
              <ul class="collection">
                <li class="collection-item" *ngFor="let student of students |async"><a [routerLink]="['./status', student.id]">{{ student.first_name }} {{ student.last_name }} ({{student.username}})</a>  </li>
              </ul>
            </div>
          </div>
          <div class="col s12 m8">
              <div class="row" style="margin-left: 100px;">
                <div>Delete by last-login date</div>
                <input type="date" name="date" id="date" #lastDate>
                <input type="button" (click)="deleteByDate2(lastDate)" value="Delete">
              </div>
              <div><mat-spinner *ngIf="showSpinner" class="spinner"></mat-spinner></div>
              
              <div class="row" style="margin-left: 150px;">
                  <router-outlet></router-outlet>
              </div>
          </div>
        </div>
      </div>
    </div>
 </main>






