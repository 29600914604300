import { Component, OnInit,  Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-message',
  templateUrl: './edit-message.component.html',
  styleUrls: ['./edit-message.component.css']
})
export class EditMessageComponent implements OnInit {
  @Input() display_close:boolean = false;
  @Output() closed = new EventEmitter();
  constructor() {
    //Nothing here
   }

  ngOnInit() {
    //Nothing here
  }
  
  close(){
    this.closed.emit()
  }

}
