import { Injectable } from '@angular/core';
import { ChatMessage } from './chat-message';
import { ApiService } from '../share/api.service'
declare var moment: any;

const DEFAULT_PROFILE_IMG = "https://res.cloudinary.com/louiseyoma/image/upload/v1546701687/profile_pic.png"

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private apiService: ApiService;
  public messages : ChatMessage[] = [];
  // public messages: Subject<Message>;

	// constructor(wsService: WebSocketService) {
	// 	this.messages = <Subject<Message>>wsService
	// 		.connect(environment.WS_URL)
    //
	// 	 .map((response: MessageEvent): Message => {
	// 			let data = JSON.parse(response.data);
	// 			return {
	// 				author: data.author,
	// 				message: data.message
	// 			}
	// 		});
	// }
 
  // Nipun's change, formatProfileImg() is not being used to render chat room's chat message profile images anymore
	public formatProfileImg(profileImgUrl:string){
      var image_url = "";
      // test code 
      // var cachedProfileImgUrl = sessionStorage.getItem("profile_img")
      // if(cachedProfileImgUrl != null && cachedProfileImgUrl != ""){
      if(profileImgUrl){

        if(
          profileImgUrl.startsWith("https://") || 
          profileImgUrl.startsWith("http://") 
        ){
          image_url =  profileImgUrl
        }
      }else{
        image_url = DEFAULT_PROFILE_IMG
      }
      return image_url
  }

  // Nipun's change, changed function logic to bypass .indexOf() condition
  public formatTime(timestamp){
    return moment(Number(timestamp)).format('LT')
  }

}

export interface Message {
	author: string,
	message: string
}
