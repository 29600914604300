import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  course_page: boolean = true;
  constructor() {
    // This is empty
  }

  ngOnInit() {
    // This is empty
  }

}
