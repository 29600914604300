<div class="row">
  <div class="col-lg-11">
    <div class="section">
      <div class="card" *ngIf="labs | async as labs">
        <div class="card-title">Labs for {{ labs.topic }}</div>
        <div class="card-content" id="chat_div_space">
          <ol>
            <li *ngFor="let item of labs.labs" class="labs-section-li">
              <div class="lab_task">
                <span class="task_comment">{{ item.comment }}</span>
              </div>
              <div class="lab_task">
                <span class="task_note">
                  <strong>Note:</strong>
                  <br />
                  <span>{{ item.note }}</span>
                </span>
              </div>
              <div class="lab_task">
                <span class="task_task"><strong>Task: </strong>{{ item.task }}</span>
              </div>
              <div>
                <span class="task_hint"><strong> Hint: </strong>{{ item.hint }}</span>
              </div>
              <div class="lab_submittion" >
                <!-- Upload button  -->
                <div *ngIf="item.submitted.length === 0" class="upload_task_section" >
                  <label for="file-input" id="LblBrowse" class="upload_lab" [ngStyle]="{'background': selectedFile === item.id ? '#ACB7EB' : 'linear-gradient(180deg, #830164 0%, #CC0150 100%)' }">
                    <i class="fa fa-check" *ngIf="selectedFile === item.id" aria-hidden="true"></i>
                    <img *ngIf="item.submitted.length === 0 && selectedFile != item.id" class="upload_imag" src="../../../assets/image/ep_upload.svg" />
                    <span *ngIf="item.submitted.length === 0 && selectedFile != item.id" class="btn-stext">Upload Lab</span>
                    <span *ngIf="selectedFile === item.id" class="btn-stext"> {{ uploadedtag }}</span>
                  </label>
                  <input hidden id="file-input" (change)="fileChange($event, item.id)" type="file" />
                </div>
                <div *ngIf="item.submitted.length > 0" class="upload_task_section" >
                  <label for="file-input" *ngIf="item.submitted[0].submission_status == '0' " id="LblBrowse" class="upload_lab" [ngStyle]="{'background': selectedFile === true ? '#ACB7EB' : '#ACB7EB' }">
                    <i class="fa fa-check"  aria-hidden="true"></i>
                    <span class="btn-stext"> Submitted</span>
                  </label>
                  <label for="file-input" *ngIf="item.submitted[0].submission_status == 'pass' " id="LblBrowse" class="upload_lab" [ngStyle]="{'background': selectedFile === true ? '#ACB7EB' : '#27AE60' }">
                    
                    <i class="fa fa-check"  aria-hidden="true"></i>   
                    <span class="btn-stext"> {{ item.submitted[0].submission_status }}</span>
                  </label>
                  <label for="file-input" *ngIf="item.submitted[0].submission_status == 'failed' " id="LblBrowse" class="upload_lab" [ngStyle]="{'background': selectedFile === true ? '#ACB7EB' : '#FF617D' }">
                    <i class="fa fa-check"  aria-hidden="true"></i>
                    <span class="btn-stext"> {{ item.submitted[0].submission_status  }}</span>
                  </label>
                  <!-- <input hidden id="file-input" (change)="fileChange($event)" type="file" /> -->
                </div>
                <div>
                  <label class="uploads_la">
                    <img class="upload_imag" src="../../../assets/image/ees.svg" />
                    <!-- <label for="labupload" *ngIf="selectedFile === item.id" class="labuploadslabel">{{ filename }}</label> -->
                    <label for="labupload" *ngIf="selectedFile === item.id  " class="labuploadslabel  cd">{{ filename }}</label>
                    <label for="labupload" *ngIf="item.submitted.length > 0" class="labuploadslabel  ab">{{ getFilename(item.submitted[0].file) }}</label>
                    <label for="labupload" *ngIf="item.submitted.length === 0 && selectedFile != item.id" class="labuploadslabel  ef">Your file will be here</label>
                  </label>
                </div>
                <div class="submit_task_section">
                  <button *ngIf="item.submitted.length == 0" [ngClass]="selectedFile ? 'submites' : 'submit_task '"  (click)="submitlab(item.id)">
                    Submit the task
                    <!-- <img class="left-sidess" src="../../../assets/image/arrwos.svg" /> -->
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                  <div *ngIf="item.submitted.length > 0" >
                   <div *ngFor="let submi of item.submitted">
                    <span *ngIf="submi.instructor_feedback" class="submit_task_read" (click)="openinstructor(submi.instructor_feedback, submi.instructor)" data-bs-toggle="modal" data-bs-target="#exampleModalToggle">
                    Read feedback >
                    </span>
                    <span *ngIf="!submi.instructor_feedback" class="submit_task_waiting" >
                      Waiting to be graded
                    </span>
                   </div>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade lab_instructor" id="exampleModalToggle" aria-hidden="true"
  aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      <div class="modal-header">
        <div class="plsi">
          <div>
            <img src="../../../assets/image/38491485.png" class="instructor_img" alt="">
          </div>
          <div class="title_instructor">
            <span id="exampleModalToggleLabel">Mary Sharwons</span>
            <span class="instructor_title">Instructor</span>
          </div>
        </div>
        <!-- <h5 class="modal-title" id="exampleModalToggleLabel">Modal 1</h5> -->
      </div>
      <div class="modal-body">
        {{ insfeedback }}
        
      </div>
    </div>
  </div>
</div>