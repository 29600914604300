import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef} from '@angular/core';
import { DataService } from '../../data.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './../_alert/alert.service';
import { environment } from './../../../environments/environment';
import { Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import {WebsocketService} from './../../_websocket/reconnecting-websocket';
//import ReconnectingWebSocket from 'src/app/_websocket/reconnecting-websocket';
import { ApiService } from 'src/app/share/api.service';
import { Observable, Subject, BehaviorSubject } from "rxjs/index";
import { UserModel } from "src/app/share/user-model";
import { MainChatComponent } from '../main-chat/main-chat.component';
export enum TYPE {Plain='plain', Image='image', File='file'}

@Component({
  selector: 'app-private-chat',
  templateUrl: './private-chat.component.html',
  styleUrls: ['./private-chat.component.css'],
  providers: [WebsocketService]
})

export class PrivateChatComponent implements OnInit {

  private users: Array<object> = [];
  public chat_text = '';
  public messages = [];
  private user$: Observable<UserModel>;
  public email;
  private user: {id: number, username: string};
  private activeGroup:string;
  private mutationObserver:MutationObserver;
  private routeParamSub: Subscription;
  public type=TYPE;
  //private websocket$: ReconnectingWebSocket;
  private ws: WebSocket;
  private token:string;
  private img: string;
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  
  @ViewChild('chatList', { read: ElementRef, static: false }) chatlist: ElementRef; 

  @Input()
  allmessages: any;

  @Input() dm_user: string;

  constructor( 
    public dataservice: DataService, 
    private http: HttpClient, 
    private route: ActivatedRoute,
    private alertService:AlertService,
    private apiservice: ApiService,
    private cdr: ChangeDetectorRef,
    private mainchat: MainChatComponent,
    private WebsocketService: WebsocketService,
  ) {
    this.token = sessionStorage.getItem('token');
    this.dataservice.username = sessionStorage.getItem('username');
    this.user$ = this.apiservice.getUserInfo();
    this.user$.subscribe(
      data =>{
        this.img = data.profile_img
      }
    )
    this.WebsocketService.messages.subscribe(msg => {
      if (msg.messages.action == 'sendMessage'){
          //console.log("dm message", msg)
          this.allmessages.push(msg.messages)
          //console.log("Response from websocket:", msg.messages);
      }
      
      // const data = JSON.parse(evt.data);
      // if(parseInt(data.messages.active_group) == parseInt(sessionStorage.getItem('active_ch'))){
      //   this.allmessages.push(data.messages)
      //   this.cdr.detectChanges();
      // }
    })
   }

  ngOnInit() {
    this.callWebsocket();
    console.log(localStorage.getItem('dm_user'))
   
    this.routeParamSub = this.route.params.subscribe(params => {
      //console.log(params)
      // this.changePartner(+params['id']);
    });
  }

  ngAfterViewInit(){
    this.mutableObserver();
  }

  ngOnDestroy(): void {
    this.routeParamSub.unsubscribe()
    this.WebsocketService.messages.complete()
    this.WebsocketService.messages.unsubscribe()
  }

  changePartner(partner){
    // this.messages = [];

    const partner_id = partner;
    const prepare_group = [
      partner_id,
      sessionStorage.getItem('user_id')
    ];
    var sortingarray = prepare_group.sort().join('_');
    this.activeGroup = sortingarray;
    // this.websocket$.onmessage({
    //   action:'getRecentMessages',
    //   active_group:this.activeGroup,
    //   token:this.token
    // });
  }

  
  async addEmoji(event) {
    // console.log(this.message)
    const { chat_text } = this;
    // console.log(message);
    // console.log(`${event.emoji.native}`)
    const text = `${chat_text}${event.emoji.native}`;

    this.chat_text = text;
    // this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    //console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  onFocus() {
    //console.log('focus');
    this.showEmojiPicker = false;
  }
  async sendMessage(message, type:string) {
    const userProfileImg = sessionStorage.getItem("profile_img")
    if(this.dataservice.profileImgIsSet()){
      if(message!==''){
        const now = new Date();
        let payload = {
            action:'sendMessage',
            active_group: sessionStorage.getItem('active_ch'),
            username: this.dataservice.username,
            profile_img: this.img,
            content: message,
            the_type:type,
            timestamp: now.getTime(),
            token:this.token,
            private: true
        }
        let messages = {
          source: '',
          content: '',
          messages: '',
        };
        messages.source = 'localhost';
        messages.content = JSON.stringify(payload);
        this.WebsocketService.messages.next(messages);
      }
      this.chat_text = '';
    }
    else
    {
      this.alertService.error(
        'You need to set your profile image to continue'
      );
    }
  }

//   const now = new Date();
//   let context = {
//     action: 'sendMessage',
//     active_group: this.activeGroup,
//     user: sessionStorage.getItem('username'),
//     user_profile_img: userProfileImg,
//     content: message,
//     the_type: type,
//     timestamp: now.getTime(),
//     // timestamp:this.onlyHsMs(now.toLocaleTimeString()),
//     token: this.token
//   };

//   if (this.qoute_message) {
//     let { profile_img, ...qoute_m } = this.qoute_message
//     context['qoute_message'] = qoute_m
//   }
//   if (window.navigator.onLine) {
//     this.websocket.send(JSON.stringify(context));

//   }

//   // this.websocket.onmessage
//   this.qoute_message = null;
//   this.chat_text = '';
//   this.is_send_message = true
// }

  async callWebsocket(){
  //   this.websocket$ = new ReconnectingWebSocket(environment.WS_URL);
  //   this.websocket$.onopen = (evt) => {
  //     //// get message with api
  //     console.log("connected to websocket prviate");
  //     // this.getprivateMessages()
  //   }
  //   this.websocket$.onmessage = (evt) => {
  //     const data = JSON.parse(evt.data);
  //     if(parseInt(data.messages.active_group) == parseInt(sessionStorage.getItem('active_ch'))){
  //       this.allmessages.push(data.messages)
  //       this.cdr.detectChanges();
  //     }
  // }
    // this.websocket$.subscribe((messages)=>{
    //   if(messages['active_group'] == this.activeGroup){
    //     if (messages['messages'] !== undefined) {
    //       messages['messages'].forEach((message)=>{
    //           const messg = message.user+': '+message.content;
    //           this.messages.push(messg);
    //         }
    //       );
    //     }
    //   }
    // });
  }

  mutableObserver(){
    this.mutationObserver = new MutationObserver((mutations) => {
        this.scrollToBottom();
      }
    );

    this.mutationObserver.observe(this.chatlist.nativeElement, {
        childList: true
    });
  }

  private scrollToBottom(){
    try {
      this.chatlist.nativeElement.scrollTop = 
      this.chatlist.nativeElement.scrollHeight;
      const chat_scroll = document.getElementById('chat_div_space');
      chat_scroll.scrollTop = chat_scroll.scrollHeight;
    } catch (err) {}
  }

}
