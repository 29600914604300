 <main>
    <div class="rectangle">
      <div class="section container">
        <div class="row" >
          <div class="col s12 m4">
            <span class="settings-title">Topics</span>
            <div class="row">
              <ul class="collection">
                <li class="collection-item" *ngFor="let topic of topicsSub |async"><a [routerLink]="['./topic', topic.id]"> {{topic.topic}} </a>  </li>
              </ul>
            </div>
          </div>
          <div class="col s12 m8">
              <div class="row" style="margin-left: 50px;">
                  <router-outlet></router-outlet>
              </div>
          </div>
        </div>
      </div>
    </div>
 </main>

