import {
  Component,
  ViewContainerRef,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "../../share/api.service";
import { WebsocketService } from "../../_websocket/reconnecting-websocket";
import { MenuContextualService } from "src/app/share/menu-contextual.service";

@Component({
  selector: "app-main-chat",
  templateUrl: "./main-chat.component.html",
  styleUrls: ["./main-chat.component.css"],
  providers: [WebsocketService],
})
export class MainChatComponent implements OnInit {
  thing: boolean = false;
  search: any;
  member_id: number;
  private subscription: any;
  private child: any;
  allrooom: any;
  filteruser: any;
  username: string;
  public href: any;
  currentUrl: string;
  private routeParamSub: Subscription;
  public messages: any = [];
  dm_user: string;

  @ViewChild('userMenu', {static: true}) userMenu: TemplateRef<any>; 
  constructor(
    private popupService: MenuContextualService,
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.username = sessionStorage.getItem("username");
  }
  ngOnInit() {
    this.getUrl();
    var memberid = this.route.snapshot.firstChild.paramMap.get("room_id");
    sessionStorage.setItem("active_ch", memberid);
    this.getallgroup();
    if (memberid !== "topic-chat") {
      this.getprivateMessages(parseInt(memberid));
    }
    this.routeParamSub = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd && this.child !== this.route.firstChild) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.child = this.route.firstChild;
        this.subscription = this.route.firstChild.url.subscribe((x) => {
          sessionStorage.setItem("active_ch", x[0]["path"]);
          this.getUrl();
          this.getallgroup();
          if (x[0]["path"] !== "topic-chat") {
            this.getprivateMessages(this.href);
          }
        });
      }
    });
  }
  
  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    this.routeParamSub.unsubscribe();
    this.popupService.close("close")
    this.Offline()
  }
  async Offline(){
      this.apiService.OnlineStatus("no").subscribe(
          (data) => {
            //console.log("hodsn");
            //console.log(data);
          },
          (error) => {
            console.log(error);
          }
      );
  }
  async getUrl() {
    var cunnert = this.router.url.split("/");
    this.href = cunnert[cunnert.length - 1];
  }
  
  isCureentTab(id) {
    
    if (id == this.href) {
      return true;
    } else {
      return false;
    }
  }
  setUser(members){
    this.dm_user = members.first_name+" "+members.last_name
  }
  getprivateMessages(member_id) {
    this.apiService.getPrivateMessages(member_id).subscribe(
      (data) => {
        this.messages = data;
      },
      (error) => {
        console.error("No message found");
      }
    );
  }
  getallgroup() {
    this.apiService.getAllRoom().subscribe(
      (data) => {
        this.allrooom = data;
        // console.log("All group", this.allrooom)
        //this.cdr.detectChanges()
      },
      (error) => {
        console.log("an error", error);
      }
    );
  }

  filterItem(item: any[], value: string): void {
    if (!item) {
    }
    if (value == "") {
      this.getallgroup();
    } else {
      this.allrooom = item.filter((a) =>
        a.members.find((e) =>
          e.first_name.toLowerCase().startsWith(value.toLowerCase())
        )
      );
    }
  }
  getSurnameChar(surname: string) {
    return surname.charAt(0);
  }

  async getRoomid(id: number) {
    this.apiService.getPivateRoom(id).subscribe(
      (data) => {
        this.getallgroup();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  async open({x,y}:MouseEvent, menu, group)
  {
    this.popupService.open({x, y}, this.userMenu, this.viewContainerRef, group)
    .subscribe(res=>{
      console.log(res)
    })
  }
  async deletecontact(group){
    this.apiService.deletePrivateMessages(group.id).subscribe((data)=>{
      this.getallgroup()
      this.popupService.close("close")
    },(error)=>{
      console.log(error)
    })
  }
}
