<div class="nav">
  <div fxFlex="1 0 auto" class="nav-logo">
      <img src="/assets/image/Final-Logo.png" alt="no image" >
  </div>
  <div fxFlex="0 1 auto" class="nav-right" fxLayout="row" >
    <div fxLayout="row" class="nav-profile">
      <img class="avatar" src="/assets/image/avatar.png" alt="no image" >
      <div class="nav-profile-btn">
        <div class="nav-name">
          Eric Serra
        </div>
        <div class="nav-subname">
          profile
        </div>
      </div>
    </div>
    <div class="nav-divider"></div>
    <div class="nav-btn">
      <div class="nav-name">
        Light
      </div>
      <div class="nav-subname">
        theme
      </div>
    </div>
    <div class="nav-logout" fxLayout="row" fxLayoutAlign="space-evenly center">
      <img src="assets/image/nav/question.svg" alt="no image" >
      <img src="assets/image/nav/logout.svg" alt="no image" >
    </div>
  </div>
</div>
