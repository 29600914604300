import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import {ApiService} from "../../share/api.service";
import {environment} from "../../../environments/environment";
import {ClassModel} from "../../share/class-model";
import {Observable} from "rxjs/index";
import {UserModel} from "../../share/user-model";
import { AlertService } from './../../course/_alert/alert.service';
import {DataService} from "../../data.service";
import processHelper from './../profile/process.helper'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public user$:Observable<UserModel>=null;
  public group$:Observable<ClassModel>=null;
  public environment = environment;
  public user = new UserModel();
  public img:any;
  public isUploading: boolean;
  public iscompleted: boolean;
  constructor(
    private apiService:ApiService, 
    private dataservice: DataService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  logout(){
    this.dataservice.logout(); 
  }

  public openClasses(){
    $('#sclass').click(function(){
      $('.dropdown-trigger').show()
    });
  }

  ngOnInit() {
    this.group$ = this.apiService.getGroupInfo(sessionStorage.getItem('active_group'))
    this.user$ = this.apiService.getUserInfo()
    this.user$.subscribe(
      data =>{
        this.img = data.profile_img
      }
    )
  }

  fileChange(event): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file = fileList[0];

      const formData = new FormData();
      formData.append('file', file, file.name);
      this.isUploading = true
      this.user.profile_img = processHelper.loadingImage
      this.apiService.uploadImage(formData)
        .subscribe(
          data => {
            sessionStorage.setItem('profile_img', data['profile_img'])
            this.isUploading = false
            this.dataservice.updatedImgUrl = data['profile_img']
            this.img = data['profile_img']
            this.alertService.success('Profile successfully updated');
          },
          error => {
            this.user.profile_img = error['error']['profile_img']
            this.dataservice.updatedImgUrl = error['error']['profile_img']
            this.isUploading = false
            this.img = error['error']['profile_img']
            this.alertService.error('Image was not Updated');

          }
        );
    }
  }
  public getImgUrl(prevUrl){
    if(
      prevUrl.startsWith("https://") || 
      prevUrl.startsWith("http://") 
    ){
      return prevUrl
    }
    
    return this.dataservice.profileImgIsSet()?
       this.dataservice.updatedImgUrl || (environment.API_URL + prevUrl):
       prevUrl;
  }


}
