import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {MainClassroomComponent} from './main-classroom/main-classroom.component';
import {ClassroomComponent} from './classroom.component';

const classroomRoutes: Routes = [
  {
    path: 'class',
    component: ClassroomComponent,
    // canActivate: [AuthGuard],
    children: [
        { path: '', component: MainClassroomComponent},
      ]
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(classroomRoutes),
  ],
  exports: [RouterModule],
  declarations: []
})
export class ClassroomRoutingModule { }
