
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';
import {VerificationComponent} from "./course/verification/verification.component";
import {AdminGuard} from "./verification.guard";
import {CourseListComponent} from "./course/course-list/course-list.component";
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';


const routes: Routes = [
  { path: '', redirectTo: '/classroom', pathMatch: 'full'},
  { path: 'classroom', 
    component: CourseListComponent, 
    canActivate: [AuthGuard], 
  },
  // { path: 'dashboard', component: ContentComponent},
  { path: 'home', component: HomeComponent},
  { path: 'v', component: VerificationComponent},
  { path: 'x', component: CourseListComponent},
  { path: 'admin', component: AdminDashboardComponent,canActivate: [AdminGuard] },
];
// {
//   path: 'app',
//   canActivate: [InstructorPageGuardService],
//   component: AppLayoutComponent,
//   loadChildren: () => import('./main/instructor/instructor.module').then(m => m.InstructorModule),
// },



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard]
})
export class AppRoutingModule { }
