<nav aria-label="Site menu" class="navbar navbar-expand-lg navli d-md-none d-sm-none d-xs-none d-lg-flex"
    id="big-screen">
    <div class="d-flex justify-content-lg-end ml-lg-5" style="width: 20%;">
        <a class="navbar-brand" href="#">
            <img style="width: 156px;" src="/assets/image/Final-Logo.png" alt="no image">
        </a>
    </div>
    <div class="d-flex justify-content-center" id="middle-classroom">
        <ul class="nav ">
            <li class="nav-item">
                <a [routerLink]="['/dashboard/profile']" class="nav_links">Classroom Profile</a>
            </li>
        </ul>
    </div>
    <div class="d-flex mr-lg-5" style="width: 20%;">
        <a class="nav_link_s" (click)="gotoClassroom()">Go to Classroom <span><img
                    src="../../../assets/image/arrow_right.png" class="left_arrow" alt=""></span></a>
    </div>
</nav>

<nav aria-label="Site menu" class="navbar navbar-expand-lg navli d-lg-none">
    <div class="d-flex justify-content-lg-end ml-lg-5 first-class">
        <a class="navbar-brand md-screen" href="#">
            <img style="width: 156px;" src="/assets/image/Final-Logo.png" alt="no image">
        </a>
        <a class="navbar-brand mr-screen" href="#">
            <img src="/assets/image/img_30.png" alt="no image">
        </a>
    </div>
    <div class="justify-content-center" id="middle-classroom">
        <ul class="nav ">
            <li class="nav-item">
                <a [routerLink]="['/dashboard/profile']" class="nav_links">Classroom Profile</a>
            </li>
        </ul>
    </div>
    <div class="d-flex mr-lg-5  third-class">
        <a class="nav_link_s" (click)="gotoClassroom()">Go to Classroom <span><img
                    src="../../../assets/image/arrow_right.png" class="left_arrow" alt=""></span></a>
    </div>
</nav>