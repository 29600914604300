import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from "../share/api.service";
import { ChatMessage } from "../share/chat-message";
import { Observable, Subject, BehaviorSubject, Subscription } from "rxjs/index";
import { debounceTime, distinctUntilChanged, switchMap, tap, map, filter, timestamp } from 'rxjs/operators';
import { MatList } from "@angular/material";
import { environment } from "../../environments/environment";
import { UserModel } from "../share/user-model";
import { AlertService } from './_alert/alert.service';
// /import ReconnectingWebSocket from '../_websocket/reconnecting-websocket';
import {WebsocketService} from '../_websocket/reconnecting-websocket';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap  } from '@angular/router';
import { ChatService } from '../share/chat.service';
import { Howl } from 'howler'
import { Router } from "@angular/router"
import { WebSocketSubject } from 'rxjs/webSocket';
import { AMQPWebSocketClient } from '@cloudamqp/amqp-client'
export enum TYPE { Plain = 'plain', Image = 'image', File = 'file' }

@Component({
  selector: 'app-topic-chat',
  templateUrl: './topic-chat.component2.html',
  styleUrls: ['./topic-chat.component.css'],
  // providers: [WebsocketService]
})




export class TopicChatComponent implements OnInit {

  private mutationObserver: MutationObserver;
  private activeGroup: string;
  public messages: ChatMessage[] = []
  public current_user: string;
  public users: Array<object> = [];
  public chat_text = '';
  public the_message: ChatMessage;
  //public websocket: ReconnectingWebSocket;
  // public websocket: webSocket;
  //public ws: ReconnectingWebSocket;
  public email;
  public type = TYPE;
  public seconds = 0

  public avatar: string;
  private token: string;
  public offset_id = 0;
  public is_send_message: boolean = true;
  private old_date: any;
  private recent_message_is_set: boolean = false;
  public mention_users: string[];
  private search_mention_stream = new Subject();
  public qoute_message = null;
  public mentionConfig;
  public showEmojiPicker = false;
  public showEdited = false;
  public edit_message = false;
  public edit_message_id;
  public showparticipant = true;
  public startedtyping = 0;
  public webx: WebSocketSubject<any>;
  group_id: number;
  username: string;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';

  // getting a reference to the overall list, which is the parent container of the list items
  @ViewChild(MatList, { read: ElementRef, static: false }) matList: ElementRef;
  @ViewChild('ChatSpace', { read: ElementRef, static: false }) chatSpace: ElementRef<HTMLElement>;
  @ViewChild('chat_input', { read: ElementRef, static: false }) chat_input: ElementRef<HTMLElement>;

  private user$: Observable<UserModel>;
  public environment = environment;

  //is-typing 
  public messageForm = new FormGroup({
    message: new FormControl('', Validators.required)
  });
  public messageInput = new Subject<string>();
  public touched: Subject<boolean> = new BehaviorSubject(false);
  public typingIndicator: Observable<boolean>//
  private _whoIsTypingArr: string[] = [];
  public stickyDate: string[] = [];
  public userstyping = new Set();
  public itr = this.userstyping.values()
  private whoIsTyping$: Subject<string[]> = new BehaviorSubject([]);
  public who: Observable<string>
  public test
  public classparticipant: any;
  public page_number = 0;
  private services: Subscription;
  grouproomid: string

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private alertService: AlertService,
    private dataService: DataService,
    private route: ActivatedRoute,
    public chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private WebsocketService: WebsocketService,
  ) {
    this.token = sessionStorage.getItem('token');
    this.user$ = this.apiService.getUserInfo();
    this.current_user = sessionStorage.getItem('username');
    this.activeGroup = sessionStorage.getItem('active_group');
    this.setProfileImage();
    this.mentionConfig = {
      dropUp: 'true',
      allowSpace: 'true',
      //maxItems:10,
      mentionSelect: (item: any) => {
        return `@${item['label']} `;
      }
  };

  

    this.WebsocketService.messages.subscribe(msg => {
      console.log("Chat")
      console.log(msg)
      if(msg && msg.messages[0].reaction){
        msg.messages[0].message = msg.messages[0]['content']
        this.messages.forEach(element => {
          if (element['id'] == msg.messages[0]['id']){
            element['reaction'] = msg.messages[0].reaction
            this.cdr.detectChanges()
          }
        });
      }else{
        if(msg.messages[0].the_type == 'TYPING'){
          if(msg.messages[0].user !== sessionStorage.getItem('username')){
            //this.messages.push(msg.messages[0])
            if(!this.userstyping.has(msg.messages[0].content.name) && msg.messages[0].content.typing){
              this.userstyping.add(msg.messages[0].content.name)
            }else if( msg.messages[0].content.typing == false){
              this.userstyping.delete(msg.messages[0].content.name)
            }
          }
        }else{
          msg.messages[0].user_profile_img = msg.messages[0]['profile_img']
          msg.messages[0].message = msg.messages[0]['content']
          if(msg.messages[0].username !== sessionStorage.getItem('username')){
            this.messages.push(msg.messages[0])
          }
        }
        
      }
      
    });


  }

  ngOnInit() {
    //this.setUserMentionProp()
    this.getAllGroupId()
    this.getFormStage(sessionStorage.getItem("user_id"))
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy(){
    this.WebsocketService.messages.complete();
    this.WebsocketService.messages.unsubscribe();
    this.Offline()
    //this.services.unsubscribe();
  }


  async Offline(){
    this.apiService.OnlineStatus("no").subscribe(
        (data) => {
          //console.log("hodsn");
          //console.log(data);
        },
        (error) => {
          console.log(error);
        }
    );
}
getSetValueByIndex(setObj, index){
  return [...setObj][index]
}

  ngAfterViewInit(): void {
    
    this.messageForm.valueChanges.pipe(
      tap(() =>
        this.startTyping()),
    ).subscribe();
    this.messageInput.pipe(
      tap(() => this.startTyping()),
    ).subscribe();

    this.who = this.getTypingIndicator().pipe(filter(val => val.length > 0), map(val => {
      switch (val.length) {
        case 1: return `${val[0]} is typing`;
        case 2: return `${val[0]} and ${val[1]} are typing`;
        default: return `Several people are typing`;
      }
    }));
  }
  async getAllGroupId(){
    this.apiService.getClassMembers(sessionStorage.getItem('active_group')).subscribe(
      data => {
        this.classparticipant = data
        this.mention_users = data.map((e) => {return e['user']['username']})
      },
      error => {
        console.error(error)
      }
    );
  }
  toggleEmojiPicker() {
    //console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  onFocus() {
    //console.log('focus');
    this.showEmojiPicker = false;
  }
  async addEmoji(event) {
    const { chat_text } = this;
    const text = `${chat_text}${event.emoji.native}`;
    this.chat_text = text;
    console.log(this.chat_text)
    // this.showEmojiPicker = false;
  }

  getFormStage(userid) {
    this.apiService.getUserProfileStage(userid).subscribe(
      data => {
        if (data["profile_stage"] !== "completed") {
          this.router.navigate(['/dashboard/profile'])
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  async sendMessage(message, type: string) {
    //check you are connected to internet
    console.log("ended")
    this.startedtyping = 0
    this.send_typing_signal(false)
    const userProfileImg = sessionStorage.getItem("profile_img")
    if (this.dataService.profileImgIsSet()) {
      if (message.length !== 0 && message.trim() !== "") {
        if (this.edit_message) {
          const now = new Date();
          let context = {
            action: 'editMessage',
            active_group: this.activeGroup,
            user_profile_img: userProfileImg,
            group_id: this.group_id,
            user: sessionStorage.getItem('username'),
            id: this.edit_message_id,
            content: message.replace(/\n\r?/g, '<br />'),
            the_type: type,
            timestamp: now.getTime(),
            private: false,
            // timestamp:this.onlyHsMs(now.toLocaleTimeString()),
            token: this.token,
            edited: true
          };
          if (window.navigator.onLine) {
            
          }
          this.edit_message = !this.edit_message
          this.chat_text = '';
          this.is_send_message = true
        }
        else {
          const now = new Date();
          let context = {
            action: 'sendMessage',
            active_group: this.activeGroup,
            user: sessionStorage.getItem('username'),
            user_profile_img: userProfileImg,
            content: message.replace(/\n\r?/g, '<br />'),
            the_type: type,
            timestamp: now.getTime(),
            // timestamp:this.onlyHsMs(now.toLocaleTimeString()),
            token: this.token,
            private: false,
          };

          if (this.qoute_message) {
            let { profile_img, ...qoute_m } = this.qoute_message
            context['qoute_message'] = qoute_m
          }
          if (window.navigator.onLine) {
            let payload = {
              source: '',
              content: '',
              messages: '',
            };
            payload.source = 'localhost';
            payload.content = JSON.stringify(context);
            let append_message:ChatMessage = {
              message: message.replace(/\n\r?/g, '<br />'),
              type:'string',
              id: "",
              content: message.replace(/\n\r?/g, '<br />'),
              user_profile_img: userProfileImg,
              the_type: type,
              timestamp: now.getTime().toString(),
              username: sessionStorage.getItem('username')
              }
            this.messages.push(append_message)
            console.log("sending message",payload)
            this.WebsocketService.messages.next(payload);
          }

          // this.websocket.onmessage
          this.qoute_message = null;
          this.chat_text = '';
          this.is_send_message = true
        }
      }
    }
    else {
      this.alertService.error(
        'You need to set your profile image to continue'
      );
    }
  }

  fileChange(event): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file = fileList[0];

      const formData = new FormData();
      formData.append('file', file, file.name);

      this.apiService.uploadFile(formData)
        .subscribe(
          data => {
            this.sendMessage(data['url'], data['type'])
          },
          error => console.log(error)
        );
    }
  }
  mergeset( itr){
    let arr =[]
    itr.forEach(x => arr.push(x))
    return arr.join(" and")
    
  }
  callWebsocket() {
    this.group_id = +this.route.parent.snapshot.paramMap.get('group_id');
    this.username = this.route.parent.snapshot.paramMap.get("username")
    //this.websocket = new ReconnectingWebSocket(environment.WS_URL)
    
    // let we = new WebsocketService()
    
    // this.websocket.onopen = (evt) => {
    //   this.getMessages()
    // };

    // this.websocket.onmessage = (evt) => {

    //   // set today sticker

    //   // Nipun's change, once a response from the backend is received then we create a ChatMessage object, populate it with recieved data
    //   // and inserted into the messages array
    //   const data = JSON.parse(evt.data);
    //   if (data.active_group == this.activeGroup) {
    //     if (data.messages instanceof Array) {
    //       //play push audio

    //       if (data.messages[0].username !== sessionStorage.getItem('username')) {
    //         var sound = new Howl({
    //           src: [environment.PUSH_AUDIO],
    //           html5: true
    //         });
    //         sound.play();
    //         // desktop notification
    //         this.notifyMe(data.messages[0].username, data.messages[0].content, data.messages[0].profile_img)
    //       }
    //       var chatMessage = new ChatMessage()
    //       chatMessage.message = data.messages[0].content
    //       chatMessage.username = data.messages[0].username
    //       chatMessage.timestamp = data.messages[0].timestamp
    //       chatMessage.the_type = data.messages[0].the_type
    //       chatMessage.user_profile_img = data.messages[0].profile_img
    //       chatMessage.content = data.messages[0].content
    //       this.messages.push(chatMessage)

    //       this.chatService.messages.push(chatMessage)
    //       // this.ser.wse.next({message:"dsnskjnd"})
    //       // this.chatService.messages = this.messages;
    //       //this.cdr.detectChanges();

    //     }

    //   }

    //   // Nipun's change, no need to process message's as it will be processed in OtherChatComponent
    //   this.processMessages(data)
    // };
    
  }
  
  showparticipants(){
    if (this.showparticipant == true){
      this.showparticipant = false
    }else{
      this.showparticipant = true
    }
  }
  notifyMe(name, message, icon) {

    if (Notification.permission === 'granted') {
      if (document.hidden) {
        var notify = new Notification(name + ' in "Linuxjobber classroom" ', {
          body: message,
          icon: icon
        });
        notify.addEventListener('click', function () {
          window.parent.focus();
          notify.close();
        });
      } else {
        console.log("welcome back!")
      }

    } else {
      // request permission from user
      Notification.requestPermission().then((p) => {
        if (p === 'granted') {

          document.addEventListener('visibilitychange', function () {
            if (document.hidden) {
              var notice = new Notification(name + ' in "Linuxjobber classroom" ', {
                body: message,
                icon: icon
              });
              notice.addEventListener('click', function () {
                window.parent.focus();
                notice.close();
              });
            } else {
              console.log("welcome back!")
            }
          }, false);

        } else {
          console.log('User blocked notifications.');
        }
      }).catch((err) => {
        console.error(err);
      })
    }

  }
  async getMessages() {
    this.page_number++
    let service = this.apiService.getMessages(this.activeGroup, this.page_number).subscribe(
      data => {
        console.log(data)
        this.is_send_message = true
        if (this.messages.length == 0){
          this.messages = data['results'].reverse()
        }else{
          // merge two arrays together
          this.is_send_message = false
          this.messages = [ ...data['results'].reverse(), ...this.messages]; 
        }
        this.mutableObserver();
      },
      error => console.log(error)
    );
  }

  searchMention(search_txt: string) {
    const data = {
      active_group: this.activeGroup,
      search_text: search_txt,
      token: this.token
    };
    this.search_mention_stream.next(data);

  }

  getProfileImg(proImgUrl: string) {
    var image_url = "";
    if (
      proImgUrl.startsWith("https://") ||
      proImgUrl.startsWith("http://")
    ) {
      image_url = proImgUrl
    }
    else {
      image_url = environment.API_URL + proImgUrl
    }
    return image_url
  }

  qoute(message) {
    this.qoute_message = message
    this.chat_input.nativeElement.focus()
  }

  closed() {
    this.qoute_message = null
    this.edit_message = false
  }
  date_joined(date_joined, previous_message_date, date_timestamp){
    const date = new Date(date_joined)
    const pdate = new Date(parseInt(previous_message_date))
    const tdate = new Date(parseInt(date_timestamp))
    console.log(date, tdate)
    if (date.getDate() > tdate.getDate() || date.getDate() == tdate.getDate()){
      //console.log(date, tdate)
      return true
    }
  }
  formatDate(that_day) {
    // console.log(that_day)
    const date = new Date(parseInt(that_day))
    const today = new Date()
    const yesterday = new Date()
    let format_date = date.toLocaleDateString(
      'en-US', { weekday: 'long', month: 'long', day: 'numeric' }
    )

    yesterday.setDate(today.getDate() - 1)
    if (date.toLocaleDateString() == today.toLocaleDateString()) {
      format_date = 'Today'
    } else if (date.toLocaleDateString() == yesterday.toLocaleDateString()) {
      format_date = 'Yesterday'
    }
    return format_date
  }


  private processMessages(data) {
    if (data['active_group'] == this.activeGroup) {
      if (data['message'] === 'start_typing') {
        this.onTypingStarted(data["who"])
        this.typingIndicator = this.getTypingIndicator().pipe(map(val => val.length > 0));
      }
      else if (data['message'] === 'end_typing') {
        this.onTypingEnded(data["who"])
      }
      if (data['messages'] !== undefined) {

        if (data['is_next']) {
          this.chatService.messages = [...data['messages'], ...this.chatService.messages]
          this.chatSpace.nativeElement.scrollTop = 100
        }
        else if (data['from_where'] === 'send') {
          this.chatService.messages = [...this.chatService.messages, ...data['messages']]
          this.is_send_message = true
        }
        else if (data['edit']) {
          let index_of_edit = this.chatService.messages.findIndex(val => { return val.id == data['messages']['id'] })
          this.chatService.messages[index_of_edit] = data['messages']
        }
        else {
          this.chatService.messages = data['messages']
          this.is_send_message = true
        }
      }
    }
  }

  // auto-scroll fix: inspired by this stack overflow post
  // https://stackoverflow.com/questions/35232731/angular2-scroll-to-bottom-chat-style
  private scrollToBottom() {
    try {
      this.chatSpace.nativeElement.scrollTop = this.chatSpace.nativeElement.scrollHeight
      this.matList.nativeElement.scrollTop = this.matList.nativeElement.scrollHeight;
    } catch (err) { }
  }
  private scrollTotop() {
    try {
      this.chatSpace.nativeElement.scrollTo(0, 0)
      this.matList.nativeElement.scrollTo(0, 0)
    } catch (err) { }
  }
  private mutableObserver() {
    this.mutationObserver = new MutationObserver((mutations) => {
      //this.scrollToBottom();
      if (this.is_send_message) {
        this.scrollToBottom();
      }else{
        this.scrollTotop()
      }
    });

    this.mutationObserver.observe(this.matList.nativeElement, {
      childList: true
    });
  }


  private setProfileImage() {
    this.avatar = this.dataService.profileImgIsSet() ?
      sessionStorage.getItem('profile_img')
      :
      environment.API_URL + `media/avatar.png`;
  }

  private onlyHsMs(time) {
    time = time.split(':')
    let ampm = time.pop()
    ampm = ampm.split(' ')
    return `${time[0]}:${time[1]} ${ampm[1]}`
  }
  public onTypingStarted(who) {

    if (this._whoIsTypingArr.indexOf(who) == -1) {
      this._whoIsTypingArr.push(who);
      this.whoIsTyping$.next(this._whoIsTypingArr);
    }
  }

  public onTypingEnded(who) {
    this._whoIsTypingArr.splice(this._whoIsTypingArr.findIndex(val => val === who), 1);
    this.whoIsTyping$.next(this._whoIsTypingArr);
  }

  public getTypingIndicator(): Observable<any> {
    return this.whoIsTyping$;
  }

  /*
    Nipun's change, no need to send messages to AWS Websocket when this.ws is the prefered Websocket, 
    therefore this event listener method can be safely deleted 
  */
  public startTyping(): void {

    if (this.startedtyping < 2){
      this.send_typing_signal(true)
      this.startedtyping++
    }
    
  }

  /*
    Nipun's change, no need to send messages to AWS Websocket when this.ws is the prefered Websocket, 
    therefore this event listener method can be safely deleted 
  */
  public endTyping(event): void {
    this.chat_text = event.target.value;
    // console.log(event.keyCode, event.key)
    if (event.keyCode == 13 && !event.shiftKey) {
        this.sendMessage(this.chat_text, TYPE.Plain)
        this.is_send_message = true
        event.preventDefault();
        event.target.value = null;
    }
    
    // console.log("ended")
    // this.send_typing_signal(false)
    // this.startedtyping++
  }
  public getformatDate(that_day) {
    const date = new Date(parseInt(that_day))
    return date.toLocaleDateString()
  }
  public stickyfilterdate(date) {
    this.old_date = this.getformatDate(date)
  }

  public dateDifferent(timestamp) {
    if (document.getElementById(timestamp)) {
      return true

    } else {
      return false
    }
  }
  public isTyping(event: any) {
    this.messageInput.next(event)
    
    if (event == ''){
      this.startedtyping = 0
      this.send_typing_signal(false)
    }
    
    return true
  }
  public send_typing_signal(status:boolean){
    
    const now = new Date();
    let payload = {
      source: '',
      content: {},
      messages: '',
    };
    payload.source = 'localhost';
    let context = {
      action: 'sendMessage',
      active_group: this.activeGroup,
      user: sessionStorage.getItem('username'),
      user_profile_img: "userProfileImg",
      content: {"name": sessionStorage.getItem('username'), "typing": status},
      the_type: "TYPING",
      timestamp: now.getTime(),
      token: this.token,
      private: false,
    };
    payload.content = JSON.stringify(context)
    //console.log("sending", payload)
    // this.WebsocketService.messages.next(payload);
  }

  public stoptyping(){
    console.log("stopped")
  }
  // public toggleEmojiPicker() {
  //   this.showEmojiPicker = !this.showEmojiPicker;
  // }
  // public addEmoji(event) {
  //   const { chat_text } = this;
  //   const text = `${chat_text}${event.emoji.native}`;

  //   this.chat_text = text;
  //   this.showEmojiPicker = false;
  // }

  public editMessage(message) {
    this.chat_text = message.content;
    this.edit_message_id = message.id
    this.edit_message = true
  }

}
