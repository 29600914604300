<div class="row">
  <div class="col-lg-4 col-xl-4">
    <div class="chat-left-side">
      <div class="chat-left-side-body">
        <em class="material-icons search">search</em>
        <input
          type="text"
          class="search-input"
          placeholder="Search user"
          id="search"
          name="search"
          #mysearch
          [(ngModel)]="search"
          (input)="filterItem(allrooom, mysearch.value)"
        />
      </div>
      <div class="">
        <ul class="left-side-scroll">
          <li [class.active]="isCureentTab('topic-chat')">
            <a [routerLink]="['./topic-chat']">
              <div class="general_chat">
                <span class="general_chat_a">
                  <img id="general_chat_a_img" src="../../assets/image/chat-icon.svg" alt="" />
                  <span class="general_chat_a_text">General Chat</span>
                </span>
                <em class="material-icons forward-play-icon"
                  >arrow_forward_ios</em
                >
              </div>
            </a>
          </li>

          <div *ngFor="let members of allrooom">
            <div
              class="collection-item"
              *ngFor="let member of members.members"
              (contextmenu)="
                open($event, userMenu, members); $event.preventDefault()
              "
            >
              <li
                *ngIf="member.username !== username"
                class="chat-left-users"
                [class.active]="isCureentTab(members.id)"
                (click)="setUser(member)"
              >
                <a [routerLink]="['./', members.id]"
                  ><div class="inner_chat">
                    <div class="inner_chat_a">
                      <div id="inner_chat_img"><img [src]="member.profile_img" alt="" srcset="" />
                      <span class="online"  *ngIf="member.online == 'yes'"></span>
                      <span class="offline"  *ngIf="member.online == 'no'"></span></div>
                      <span class="inner_chat_b">
                        <b class="username-a"
                          >{{ member.first_name }}
                          {{ getSurnameChar(member.last_name) }}.</b
                        ><br />
                        <small class="last_message">{{
                          members.last_message
                        }}</small>
                      </span>
                    </div>
                    <em class="material-icons forward-play-icon"
                      >arrow_forward_ios</em
                    >
                  </div></a
                >
              </li>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-xl-8">
    <ng-container #group_chat *ngIf="href == 'topic-chat'"
      ><app-topic-chat></app-topic-chat>
    </ng-container>
    <ng-container #private_chat *ngIf="href !== 'topic-chat'">
      <app-private-chat [allmessages]="messages" [dm_user]="dm_user"></app-private-chat>
    </ng-container>
  </div>
</div>

<ng-template #userMenu let-data>
  <section class="user-menu">
    <ul class="user-menu-ul">
      <li><svg style="width:15px;height:15px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
    </svg><span>Add to favorites</span></li>
      <li>
        <svg style="width:15px;height:15px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
        </svg><span>View profile</span></li>
      <li>
        <svg style="width: 15px; height: 15px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"
          /></svg><span>Mark as unread</span>
      </li>
      <li class="" (click)="deletecontact(data)">
        <svg style="width: 15px; height: 15px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
          />
        </svg>
        <span>Delete conversation</span>
      </li>
    </ul>
    <!-- <span (click)="delete(user)"><em class="material-icons star">star</em></span>
    <span (click)="delete(user)"><em class="material-icons user">user</em></span>
    <span (click)="delete(user)"></span>
    <span (click)="delete(user)"></span> -->
    <!-- <div (click)="openInNewWindow(user)">Open in new Window</div> -->
  </section>
</ng-template>
