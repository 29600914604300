import { Router } from '@angular/router';
import { UserProfile } from './../../share/user-profile';
import { Component, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { ApiService } from "../../share/api.service";
import { UserModel } from "../../share/user-model";
import { DataService } from 'src/app/data.service';
import processHelper from './process.helper'
import { AlertService } from './../../course/_alert/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  levelInterestForm: FormGroup;
  profile: FormGroup;
  public user = new UserModel();
  public userprofile = new UserProfile();
  public userprofiletwo: any = {};
  public attendance$;
  public environment = environment;
  private fileName = '';
  public isUploading: boolean = false
  public isStudent: boolean;
  public isStudentSelected: boolean = false;
  public isGraduate: boolean;
  public isOccupation: boolean = false;
  public isPostgratuate: boolean = false;
  public isPostgratuateSectionShowing: boolean = true;
  public CompletepostGraduateSectionShowing: boolean = true;
  public CompletepostGraduate: boolean;
  public level_of_interest: any = {};
  public disable_level_of_interest: Array<boolean> = [];
  public next_stage: string;
  public interest: any = {};
  public error_message: string;
  public isdisabled: boolean = true;
  public allrates: Array<any> = [];


  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.profile = this.fb.group({
      Firstname: [{ value: '', disabled: this.isdisabled }, [Validators.required, Validators.minLength(2)]],
      Lastname: [{ value: '', disabled: this.isdisabled }, [Validators.required, Validators.minLength(2)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      course_of_study: [{ value: '', disabled: true }],
      I_am_a_student: [{ value: '', disabled: true }],
      Graduation_date: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      cybersecurity: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      project_manager: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      developer: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      devops: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      product_manager: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      marketer: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      designer: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
      writer: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2)]],
    })
    this.profileForm = this.fb.group({
      course_study: ['', [Validators.required, Validators.minLength(2)]],
      are_you_a_student: ['', [Validators.required, Validators.minLength(2)]],
      did_you_graduate: ['', [Validators.required, Validators.minLength(2)]],
      graduation_date: [''],
      occupation: [''],
      does_your_country_require_post_graduate: ['', [Validators.required, Validators.minLength(2)]],
      completed_post_graduate: ['']
    });
    this.levelInterestForm = this.fb.group({
      cybersecurity: ['1', [Validators.required, Validators.minLength(2)]],
      project_manager: ['1', [Validators.required, Validators.minLength(2)]],
      developer: ['1', [Validators.required, Validators.minLength(2)]],
      devops: ['1', [Validators.required, Validators.minLength(2)]],
      product_manager: ['1', [Validators.required, Validators.minLength(2)]],
      marketer: ['1', [Validators.required, Validators.minLength(2)]],
      designer: ['1', [Validators.required, Validators.minLength(2)]],
      writer: ['1', [Validators.required, Validators.minLength(2)]],
    })
  }

  ngOnInit() {
    this.apiService.getUserInfo(
      sessionStorage.getItem('user_id')
    ).subscribe(data => {
      this.user.username = data['username'];
      this.user.first_name = data['first_name']
      this.user.last_name = data['last_name']
      this.user.profile_img = data['profile_img']
      // this.user.profile_img= this.getProfileImg(data['profile_img'])
      this.user.id = data['id']
      this.getFormStage(data['id'])
      this.getUserInfo(data['id'])
    });
    this.attendance$ = this.apiService.getUserAttendance(
      sessionStorage.getItem('active_group')
    )
    this.removeCurrentStage()
  }



  onSubmit() {
    this.error_message = null
    this.userprofile = this.profileForm.value
    this.userprofile.profile_stage = this.next_stage
    this.apiService.userProfileForm(this.userprofile, this.user.id).subscribe(
      data => {
        this.next_stage = "stage2"
        this.error_message = null
      }, error => {
        this.error_message = error.error
        setTimeout(() => {
          this.error_message = null
        }, 5000)
      }
    )
  }
  secondForm() {
    if (this.allrates.length == 8) {
      this.userprofiletwo = this.levelInterestForm.getRawValue()
      this.userprofiletwo['profile_stage'] = this.next_stage
      this.apiService.userProfileForm(this.userprofiletwo, this.user.id).subscribe(
        data => {
          this.next_stage = "completed"
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }, error => {
          console.log("not saved")
        }
      )
    } else {
      this.error_message = "You need to score all level of interest"
      setTimeout(() => {
        this.error_message = null
      }, 3000)
    }
  }
  getFormStage(userid) {
    this.apiService.getUserProfileStage(userid).subscribe(
      data => {
        this.next_stage = data["profile_stage"]
      },
      error => {
        console.error(error)
      }
    );
  }
  makeeditable() {
    this.isdisabled = false;
    this.profile.get("Firstname").enable();
    this.profile.get("Lastname").enable();
  }
  fileChange(event): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file = fileList[0];

      const formData = new FormData();
      formData.append('file', file, file.name);
      this.isUploading = true
      this.user.profile_img = processHelper.loadingImage
      this.apiService.uploadImage(formData)
        .subscribe(
          data => {
            sessionStorage.setItem('profile_img', data['profile_img'])
            this.isUploading = false
            this.user.profile_img = data['profile_img']
            this.dataService.updatedImgUrl = data['profile_img']
            this.alertService.success('Profile successfully updated');
          },
          error => {
            console.log(error)
            this.user.profile_img = error['error']['profile_img']
            this.dataService.updatedImgUrl = error['error']['profile_img']
            this.isUploading = false
            this.alertService.error('Image was not Updated');

          }
        );
    }
  }

  changeImage(e): void {
    const file = e.dataTransfer ?
      e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.fileName = file.name;
    reader.readAsDataURL(file);
  }
  getUserInfo(userid) {
    this.apiService.getUserProfileStage(userid).subscribe(data => {
      this.profile.get("Firstname").setValue(data['user']['first_name']);
      this.profile.get("Lastname").setValue(data['user']['last_name']);
      this.profile.get("email").setValue(data['user']['email']);
      this.profile.get("course_of_study").setValue(data['course_study']);
      this.profile.get("I_am_a_student").setValue(data['are_you_a_student']);
      this.profile.get("cybersecurity").setValue(data['interest']["cybersecurity"]);
      this.profile.get("designer").setValue(data['interest']["designer"]);
      this.profile.get("developer").setValue(data['interest']["devops"]);
      this.profile.get("devops").setValue(data['interest']["devops"]);
      this.profile.get("marketer").setValue(data['interest']["marketer"]);
      this.profile.get("product_manager").setValue(data['interest']["product_manager"]);
      this.profile.get("project_manager").setValue(data['interest']["project_manager"]);
      this.profile.get("writer").setValue(data['interest']["writer"]);
    },
      error => {
        console.log(error)
      })
  }
  updateInfo(): void {
    this.user.first_name = this.profile.get('Firstname').value;
    this.user.last_name = this.profile.get('Lastname').value;
    this.apiService.updateUserInfo(this.user).subscribe(data => {
      this.user.username = data['username'];
      this.user.first_name = data['first_name']
      this.user.last_name = data['last_name']
      this.user.profile_img = data['profile_img']
      this.user.id = data['id']
      this.alertService.success(
        'Profile successfully updated'
      );
      this.isdisabled = true;
      this.profile.get("Firstname").disable();
      this.profile.get("Lastname").disable();
    }
    );
  }

  getProfileImg(proImgUrl: string) {
    var image_url = "";
    if (
      proImgUrl.startsWith("http")
    ) {
      image_url = proImgUrl
    }
    else {
      image_url = environment.API_URL + proImgUrl
    }
    return image_url
  }


  selectIsStudent(selection: boolean) {
    this.profileForm.get("are_you_a_student").markAsTouched();
    if (selection) {
      this.isStudentSelected = true;
      this.isStudent = true;
      this.profileForm.get("are_you_a_student").setValue('Yes');
    } else {
      this.isStudentSelected = true;
      this.isStudent = false;
      this.profileForm.get("are_you_a_student").setValue('No');
    }
  }
  selectIsGraduate(selection: boolean) {
    this.profileForm.get("did_you_graduate").markAsTouched();
    if (selection) {
      this.isGraduate = true;
      this.isOccupation = true;
      this.profileForm.get("did_you_graduate").setValue('Yes');
    } else {
      this.isGraduate = false;
      this.isOccupation = true;
      this.profileForm.get("did_you_graduate").setValue('No');
    }
  }
  selectIspostGraduate(selection: boolean) {
    this.profileForm.get("does_your_country_require_post_graduate").markAsTouched();
    if (selection) {
      this.isPostgratuate = true
      this.isPostgratuateSectionShowing = false
      this.profileForm.get("does_your_country_require_post_graduate").setValue('Yes');
    } else {
      this.isPostgratuate = false
      this.isPostgratuateSectionShowing = false
      this.profileForm.get("does_your_country_require_post_graduate").setValue('No');
    }
  }

  selectCompletepostGraduate(selection: boolean) {
    this.profileForm.get("completed_post_graduate").markAsTouched();
    if (selection) {
      this.CompletepostGraduateSectionShowing = false
      this.CompletepostGraduate = true;
      this.profileForm.get("completed_post_graduate").setValue('Yes');
    } else {
      this.CompletepostGraduateSectionShowing = false
      this.CompletepostGraduate = false;
      this.profileForm.get("completed_post_graduate").setValue('No');
    }
  }

  removeCurrentStage() {
    for (const x of Array(8).keys()) {
      localStorage.removeItem('current' + x);
    }
  }
  onInputChange(event, id: any, text: string) {
    this.level_of_interest[text] = event.value;
    var found = this.allrates.find(x => x.name.toLowerCase() === text);
    if (!found) {
      // Search by rate
      var searchrates = this.allrates.find(x => x.rate === this.levelInterestForm.get(text).value);
      if (!searchrates) {
        this.allrates.push({
          "name": text.charAt(0).toUpperCase() + text.slice(1),
          "rate": this.levelInterestForm.get(text).value
        })
      } else {
        this.error_message = "You cannot pick a number twice"
        setTimeout(() => {
          this.error_message = null
        }, 3000)
      }

    } else {
      var index = this.allrates.findIndex(x => x.name.toLowerCase() === text);
      var searchrates = this.allrates.find(x => x.rate === this.levelInterestForm.get(text).value);
      if (!searchrates) {
        if (index > -1) {
          this.allrates.splice(index, 1);
        }
        this.allrates.push({
          "name": text.charAt(0).toUpperCase() + text.slice(1),
          "rate": this.levelInterestForm.get(text).value
        })
      } else {
        this.error_message = "You cannot pick a number twice"
        setTimeout(() => {
          this.error_message = null
        }, 3000)
      }
    }
    // Sort array from Highest to lowest
    this.allrates.sort((a, b) => b.rate - a.rate)
  }

  resetStudentSelection() {
    this.isStudentSelected = false;
    this.isStudent = false;
  }
  resetGraduateSelection() {
    this.isOccupation = false;
    this.isGraduate = true;
  }

}
