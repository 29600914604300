import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.css']
})
export class AdminNavigationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
     // This is intentional
  }
  public gotoClassroom(){
    console.log("gotoclas clicked")
    let active_group = sessionStorage.getItem('active_group')
    this.router.navigate(['/classroom/'+active_group])
  }
}
