import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../share/api.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService,) { }

  ngOnInit() {
    // This is empty
  }
  public gotoClassroom() {
    console.log("gotoclas clicked")
    let active_group = sessionStorage.getItem('active_group')
    this.getstage(active_group)
  }
  getstage(active_group) {
    this.apiService.getUserProfileStage(sessionStorage.getItem('user_id')).subscribe(
      data => {
        if (data["profile_stage"] !== 'completed'){
          this.router.navigate(['/dashboard/profile'])
        }else{
          this.router.navigate(['/classroom/' + active_group])
        }
      },
      error => {
        console.error(error)
      }
    )
  }

}
