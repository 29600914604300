<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" href="#"><img
    style="width: 133px; height: 33px"
    src="/assets/image/Final-Logo.png"
    alt="no image" /></a>
    <div>
      <ul>
        <li class="nav-item">
          <a [routerLink]="['/dashboard/profile']" class="head-text"
            >Classroom Profile
          </a>
        </li>
      </ul>
    </div>
    <div>
      <ul class="navbar-nav ms-md-auto gap-4">
        <li class="nav-item" *ngIf="is_instructor">
          <a [routerLink]="['/admin']" class="head-text"
            >Instructor Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/dashboard/profile']" class="head-text"
            >{{ user.first_name }} {{ user.last_name }} 
            <span><img [src]="user.profile_img" alt="" class="profile_imag"></span>
          </a>
        </li>
      </ul>
    </div>
</nav>

<!-- <nav class="navbar navbar-expand-lg">
  <div class="nav-bar-width">
    <a class="navbar-brand" href="#">
      <img
        style="width: 133px; height: 33px"
        src="/assets/image/Final-Logo.png"
        alt="no image" /></a
    >
    <div>
      <ul class="navbar-nav ms-md-auto gap-4">
        <li class="nav-item" *ngIf="is_instructor">
          <a [routerLink]="['/admin']" class="head-text"
            >Instructor Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/dashboard/profile']" class="head-text"
            >{{ username }}
          </a>
        </li>
      </ul>
    </div> -->
    <!-- <span class="head-text" *ngIf="group$ |async as course">
      {{ course.name }}
      <span style="font-size: 12px"
        >{{ course.start_date}} - {{course.end_date}}</span
      >
    </span> -->
    <!-- <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <!-- <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav ms-md-auto gap-4">
        <li class="nav-item" *ngIf="is_instructor">
          <a [routerLink]="['/admin']" class="head-text"
            >Instructor Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/dashboard/profile']" class="head-text"
            >{{ username }}
          </a>
        </li>
      </ul>
    </div> -->
  <!-- </div>
</nav> -->

<div class="totalbody pt-lg-5 mt-lg-5">
  <div class="row">
    <div class="col-lg-4 col-xl-4 col-md-3 col-12">
      <div class="top-head">
        <a [routerLink]="['/dashboard/list']">
          <em class="material-icons play-icon">arrow_back_ios</em>
          <a class="back_btn" >Your Courses</a>
        </a>
      </div>

      <div class="left-pad">
        <ul class="nav nav-tabs left-tab">
          <li>
            <a class="left-tab-list" (click)="showstudent(false)" data-toggle="tab" [ngClass]="{ 'active' : showstudenta == false }">
              <span><img src="../../assets/image/menu-m.svg" class="menu-tab-icon" alt="" /></span>
              <span class="left-title-col pl-4">Topics</span></a
            >
          </li>
          <li>
            <a class="left-tab-list" (click)="showstudent(true)" data-toggle="tab" [ngClass]="{ 'active' : showstudenta == true }" >
              <span><img src="../../assets/image/student-icon.svg" class="menu-tab-icon" alt="" /></span>
              <span class="left-title-col pl-4">Students</span>| <span *ngIf="(noOfUsers$ | async) as users"
              >{{ users.length }}</span
            ></a
            >
            
          </li>
        </ul>
        <div class="tab-content clearfix">
          <div
            id="topics"
            class="tab-pane hide-side active"
            [ngClass]="{'d-flex' : showstudenta === false}"
          >
            <div
              class="course_topic_area "
             
            >
              <ul class="collection-list">
                <li
                  [class.active-link]="isCurrentRoute(topic.id)"
                  class="video-list"
                  *ngFor="let topic of (topicsSub | async)"
                >
                  <a
                    class
                    (click)="setTopic(topic.id)"
                    id="vid"
                    class="topic-list"
                  >
                  <span class="d-flex align-items-center">
                    <span class="play_arrow_button">
                      <em class="material-icons play-ic">play_arrow</em>
                    </span>
                    <b class="vid-title ml-3">{{ topic.topic }}</b>
                  </span>
                    
                    
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="students"
            class="tab-pane hide-side active"
            [ngClass]="{'d-flex': showstudenta === true}"
          >
          
            <div *ngIf="groupMembers$ as members" class="scroll-hide">
              <ul class="allstudents" >
                <div class="chat-left-side-body">
                  <em class="material-icons search">search</em>
                  <input
                    type="text"
                    class="search-input"
                    placeholder="Search user"
                    id="s_search"
                    name="student_search"
                    #msearch
                    (input)="filterItem(members, msearch.value)"
                  />
                </div>
                <li *ngFor="let member of members" >
                  <div class="samo">
                    <img [src]="member.user.profile_img" alt="" srcset="" class="mr-2">
                    <span>{{ member.user.first_name }} {{ member.user.last_name }}</span>
                  </div>
                  <a
                    (click)="getRoomid(member.user.id)"
                    class="write-a-message"
                  >
                  <img class="chat-symbol" src="../../assets/image/mdi-light_message-processing.svg" alt="" srcset="">
                   <span class="weri-text">Write a message</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xl-8 col-md-9 col-12 right-section">
      <!-- <p class="text-right session-status">
        Session status:
        <span
          [ngClass]="{'session-color': sessionstatus === 'active', 'session-grey' : sessionstatus === 'inactive' }"
        ></span>
      </p> -->
    
      <ul class="nav nav-tabs con-list">
        <li>
          <a  class="nav-item tab-tile" [class.active]="isCureentTab('/video')" *ngIf="selectedTopic" (click)="tabToVideo(selectedTopic)"><em class="material-icons play-ic">play_arrow</em><span class="nav-bar-text">Video</span></a>
        </li>
        <li>
          <a class=" tab-tile" [class.active]="isCureentTab('/lab')" *ngIf="selectedTopic" (click)="goToLab(selectedTopic)"><img src="../../assets/image/checkmark.svg" alt=""><span class="nav-bar-text">Lab</span></a>
        </li>
        <li>
          <a class="nav-item tab-tile" [class.active]="isCureentTab('/notes')" *ngIf="selectedTopic"(click)="goToNote()"><img src="../../assets/image/note-icon.svg" alt=""><span class="nav-bar-text">Note</span></a>
        </li>
        <li>
          <a class="nav-item tab-tile" [class.active]="isCureentTab('/topic-chat')" [routerLink]="['./main/topic-chat']"><img src="../../assets/image/chats.svg" alt=""><span class="nav-bar-text">Chat</span></a>
        </li>
      </ul>
      <div>
        <app-alert></app-alert>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
