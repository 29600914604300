<div class="chat-reply" >
    <span class="side-mark"></span>
    <div class="reply-content">
        <p class="reply-detail">
            <span class="close" *ngIf="display_close" (click)="close()">
               
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"></path>
                </svg>
            </span>
        </p>
        
    </div>
</div>
