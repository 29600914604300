import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { Smile, Edit, Edit2, Edit3 } from 'angular-feather/icons';

const icons = {
  Smile, Edit, Edit2, Edit3
};

@NgModule({
  imports: [
    CommonModule,
    FeatherModule.pick(icons)
  ],
  declarations: [],
  exports: [FeatherModule]
})
export class IconsModule { }
