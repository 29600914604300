<!-- Chat View -->
<div class="card medium" >
  <div class="user_identity">
    <img class="send-back" src="../../../assets/image/arrow-back.svg" alt="">
    <span class="dm_user"><b>{{ dm_user }}</b></span>
  </div>
  <div
    class="card-content"
    style="overflow: auto"
    id="chat_div_space"
    #chatList
  >
    <!-- <ul>
      <li *ngFor="let message of messages" [innerText]="message"><br></li>
    </ul> -->
    <div *ngFor="let message of allmessages; let i = index">
      <app-others-chat class="chats"
      [message]="message"
    ></app-others-chat>
    <app-my-chat class="chats"
      [message]="message"
    ></app-my-chat>
    
    </div>
  </div>
  <div class="form-group text-box-section">
    <div class="input-text-area">
      <span class="material-icons" (click) ="toggleEmojiPicker()">
        sentiment_satisfied
        </span>
      <input
        class="form-control"
        type="text"
        id="message_box"
        name="chat_text"
        [(ngModel)]="chat_text"
        placeholder="Type a message"
        (keyup.enter)="sendMessage(chat_text, 'plain')"
        (focus)="onFocus()"
      />
      <div class="image-upload">
        <label for="file-input">
          <span class="file_input_icon"><img src="../../assets/image/attach.svg" alt="" srcset="" /></span>
          <!-- <i class="icon-attach material-icons">add</i> -->
          <!-- <input
            style="display: none"
            id="file-input"
            (change)="fileChange($event)"
            type="file"
          /> -->
        </label>
      </div>
    </div>
    
    <button
      class="send"
      (click)="sendMessage(chat_text, 'plain')"
      id="submit"
    >
    <img class="send_icon" src="../../assets/image/Vector.svg" alt="">
    <span class="send_text">Send</span>
    </button>
  </div>
</div>
<emoji-mart class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>