import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatVideoModule } from 'mat-video';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MaterializeModule } from 'angular2-materialize';
import { AppRoutingModule } from './app-routing.module';
import {DataService} from './data.service';
import {Location} from "@angular/common";
import {CourseModule} from "./course/course.module";
import {ApiService} from "./share/api.service";
import {OrderModule} from "ngx-order-pipe";
import {DashboardModule} from "./dashboard/dashboard.module";
import {AdminDashboardModule} from "./admin-dashboard/admin-dashboard.module";
import {ClassroomModule} from "./classroom/classroom.module";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { WebsocketService } from './_websocket/reconnecting-websocket';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatVideoModule,
    MaterializeModule,
    AppRoutingModule,
    CourseModule,
    ClassroomModule,
    OrderModule,
    DashboardModule,
    RouterModule,
    AdminDashboardModule,
    PickerModule,
  ],
  providers: [DataService,Location,ApiService, WebsocketService],
  bootstrap: [AppComponent],
})
export class AppModule { }
