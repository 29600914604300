import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../share/api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-topic-lab',
  templateUrl: './topic-lab.component.html',
  styleUrls: ['./topic-lab.component.css']
})
export class TopicLabComponent implements OnInit {
  // route:any ;
  topic_id: any
  public labs:any;
  formData:any;
  filename: string = "Your file will be here";
  selectedFile: any = null;
  uploadedtag: string = 'Upload Lab';
  insfeedback: string = '';

  constructor(private apiService:ApiService, private route:ActivatedRoute, private router:Router) {
    this.loadlabs()
  }

  ngOnInit() {
    this.topic_id = parseInt(this.route.snapshot.paramMap.get('topic_id'));
  }

  loadlabs(){
    this.labs=this.apiService.getLabs(sessionStorage.getItem('active_topic'))
    this.labs.subscribe(
      data => {
        console.log("sdsd",data)
      }
    )
  }
  getFilename(name:string){
    let array = name.split('/')
    return array[array.length - 1]
  }
  submitlab(lab_id){
    this.apiService.submitlab(this.formData, lab_id, this.topic_id).subscribe(
      data => {
        this.filename = null
        this.loadlabs()
      },
      error => {
        console.log("error occured")
        console.log(error)
      }
    )
  }
  openinstructor(feedback, email){
    this.insfeedback = feedback
  }
  fileChange(event, id): void {
    const fileList: FileList = event.target.files;
    console.log("ssd", id)
    if (event.target.files && event.target.files.length) {
      this.filename = event.target.files[0].name.toLowerCase();
      this.uploadedtag = 'Uploaded';
      this.selectedFile = id;
      const file = fileList[0];
      this.formData = new FormData();
      this.formData.append('file', file, file.name);
    }else{
      this.selectedFile = null;
      this.uploadedtag= 'Upload Lab';
    }
  }
}
