<div
  class="chat-item"
  *ngIf="isOthers(message.username) && message.the_type !== 'date'"
>
  <div class="user_image">
    <!-- <img *ngIf="message.user_profile_img" [src]="message.user_profile_img" /> -->
    <img [src]="message.profile_img" />
  </div>
  <div class="my-message">
      <div class="my-message-body">
        <div class="my-message-body-inner">
            <div class="head">
              <span class="myname">{{ message.username }}</span>
              <span class="datetime">{{
                chatService.formatTime(message.timestamp)
              }}</span>
            </div>
            <div class="d-flex">
              <div>
                <span
              class="message-body"
              *ngIf="
                message.the_type === 'plain' ||
                (message.the_type === undefined && message.the_type !== 'date')
              "
              [innerHTML]="message.content"
            ></span>
              <span *ngIf="message.reaction" class="reactions">
                <span class="reaction">{{ getreaction(message.reaction) }}</span>
              
            </span>
              </div>
            <span class="smiling" (contextmenu)="openemoji($event, emojimenu, message)"><i class="far fa-smile"></i></span>
            </div>
          </div>
          <span class="btn_reply" (click)="qoute()" *ngIf="message.the_type === 'plain' || message.the_type === undefined && message.the_type !== 'date' ">
            <img src="../../../assets/image/reverse.svg">  
            Reply</span>
      </div>
  </div>
</div>

<!-- <div class="chat-item-o" *ngIf="isOthers(message.username) && message.the_type !== 'date'">
    <div class="other">
        <div class="image">
            <img *ngIf="message.user_profile_img" [src]="message.user_profile_img" />
            <img *ngIf="message.profile_img" [src]="message.profile_img" />
        </div>
        <div class="message">
            <div class="head">
                <h4>{{message.user}}{{message.username}}</h4>
                <i>{{ chatService.formatTime(message.timestamp) }}</i>
                <img src="../../../assets/image/qoute-icon.png" alt="" (click)="qoute()"
                    *ngIf="message.the_type === 'plain' || message.the_type === undefined && message.the_type !== 'date' ">
            </div>
            <p class="content">
                <app-qoute-message [message]="message.qoute_message" *ngIf="message.qoute_message">
                </app-qoute-message>
                <span
                    *ngIf="message.the_type === 'plain' || message.the_type === undefined && message.the_type !== 'date'"
                    [innerHTML]="message.content">
                </span>
                <span *ngIf="message.the_type === 'file' ">
                    <i> Uploaded a file: </i>
                    <a [href]="message.content" download="{{ message.username }}{{ message.user }}'s_upload"
                        class="download-icon">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </span>
            </p>
            <div class="mychat-thumb">
                <span *ngIf="message.the_type === 'image' ">
                    <a [href]="message.content" download="{{ message.username }}'s_upload" class="download-icon anorhe">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                </span>
                <app-image-lightbox *ngIf="message.the_type === 'image'"
                    [imageUrl]="chatService.formatProfileImg(message.content)">
                </app-image-lightbox>
            </div>
        </div>
    </div>
    <span *ngIf="message.edited">
        <i-feather name="edit-2" class="edit"></i-feather>
    </span>
</div> -->

<ng-template #emojimenu let-data>
  <section class="emokis" >
    <li *ngFor="let emoji of emojilist; let i = index" class="emojireaction" [attr.data-index]="i" (click)="updateemoji(data, emoji)">{{ getreaction(emoji) }}</li>
  </section>
</ng-template>