import { Injectable } from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, RoutesRecognized} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import has = Reflect.has;
import {environment} from "../environments/environment";
import {AsyncPipe} from "@angular/common";
import {ApiService} from "./share/api.service";
import { map } from 'rxjs/operators';
import { WebsocketService } from './_websocket/reconnecting-websocket';

@Injectable({
  providedIn: 'root',
  
})
export class AuthGuard implements CanActivate {
  _hash:string;


  constructor(private dataservice: DataService, private router: Router, private apiService:ApiService, private WebsocketService: WebsocketService, ) {  }

  /* 
    Nipun's change. This method will be executed every time the backend redirects the end user to the frontend's classroom page.
    First, it checks if the user has logged in previously, if so then just end the method execution and proceed in the workflow.
    If not, then the hash, provided by the backend, is fetched from the query params. If the hash is valid then grab the group id 
    from the request parameters and call the dataService.sessionSet() method. 
    
    sessionSet() will return an Observable that will be subscribed to using pipe() and map(). Inside map, we extract the 
    information provided by the backend to Angular's sessionStorage(the most important data being the username, group_id and token).
    
    Then we return a boolean value based on the result of the HTTP request make to obtain the above information and save that boolean 
    value into a variable called 'result'
    
    Finally we return the 'result' variable as the method's return value
  */
  registrationstatus(id, profile_img, token, activeGroup){
    let payload = {
      source: '',
      content: '',
      messages: id,
    };
    this.apiService.NotifyNewUser(id).subscribe(
      data => {
        const now = new Date();
        console.log(data)
        if (data == 'new-user'){
            
            payload.source = 'localhost';
            let message = {
              action: 'sendMessage',
              active_group: activeGroup,
              user: sessionStorage.getItem('username'),
              user_profile_img: profile_img,
              content: id,
              the_type: "joined",
              timestamp: now.getTime(),
              // timestamp:this.onlyHsMs(now.toLocaleTimeString()),
              token: token,
              private: false,
              }
            payload.content = JSON.stringify(message);    
        }
      },
      error => console.log(error)
    )
    //this.WebsocketService.connect(environment.WS_URL)
    this.WebsocketService.messages.next(payload)
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean> | Promise<boolean> | boolean {
      
      if (this.dataservice.islogin()) {
        return true;
        
      }
      else {
        const hash = route.queryParamMap.get('hash')
        
        if(hash){
          const group_id=route.params['group_id'];
          var result = this.dataservice.sessionSet(hash, group_id)
          .pipe(map(data => {
            sessionStorage.clear();
            sessionStorage.setItem('username', data['username']);
            sessionStorage.setItem('token', data['token']);
            sessionStorage.setItem('role', data['role']);
            sessionStorage.setItem('user_id', data['id']);
            sessionStorage.setItem('active_group', group_id);
            sessionStorage.setItem('video_required', data['video_required']);
            sessionStorage.setItem('uploaded', data['uploaded']);
            sessionStorage.setItem('profile_img', data['profile_img']);
            sessionStorage.setItem('is_instructor', data['is_instructor']);
            this.registrationstatus(parseInt(data['id']), data['profile_img'], data['token'], group_id)
            if(!!data['video_required']){
              if(!data['uploaded']){
                this.router.navigate(['/v'])
              }
              else {
                this.router.navigate(['/classroom', group_id])
              }
      
            }
            else{
              this.router.navigate(['/classroom' ,group_id])
            }
            return true;
          },error => {
              alert('Cannot communicate with server, please try again')
            return false;
          })
          )
          return result;
        }
        else{
          
          if(sessionStorage.getItem('token') !== '' ){
            this.registrationstatus(parseInt(sessionStorage.getItem('user_id')), sessionStorage.getItem('profile_img'), sessionStorage.getItem('token'), sessionStorage.getItem('active_group'))
            return true;
          }else{
            
              alert("Session expired, redirecting back to linuxjobber");
              sessionStorage.removeItem("token");
              window.location.replace(environment.API_URL);
              return false;
          }
          
        }
      }
  }

}

