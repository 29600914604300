import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef
} from '@angular/core';

import {environment} from "../../../environments/environment";
import {ApiService} from "../../share/api.service";
import {Router} from "@angular/router";

@Component({
  selector: 'videojs-record',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})

export class VerificationComponent implements OnInit {

  // reference to the element itself: used to access events and methods
  private _elementRef: ElementRef;

  // index to create unique ID for component
  // @Input() idx: string;
  idx='1';
  private config: any;
  private player: any;
  private plugin: any;

  // constructor initializes our declared vars
  constructor(elementRef: ElementRef,private apiService:ApiService,private router:Router) {
    this.player = false;

    // save reference to plugin (so it initializes)
    // this.plugin = Record;

    // video.js configuration
    this.config = {
      controls: true,
      autoplay: false,
      fluid: false,
      loop: false,
      width: 450,
      height: 400,
      controlBar: {
        volumePanel: false
      },
      plugins: {
        // configure videojs-record plugin
        record: {
          audio: false,
          video: true,
          debug: true
        }
      }
    };
  }

  ngOnInit() {
    // This is empty
  }

  uploadVideo(res): void {
            const formData = new FormData();
            formData.append('video', res, res.name);
            formData.append('active_group', sessionStorage.getItem('active_group'));

            this.apiService.uploadVideo(formData)
                 .subscribe(
                     data => {
                       const url= environment.API_URL + res['video_url'];
                       console.log(url)
                       this.router.navigate(['/classroom/'+sessionStorage.getItem('active_group')])
                     },
                     error => alert('Video not uploaded, try again')
                 );

    }

}
