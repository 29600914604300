import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../share/api.service";
import { DataService } from "../../data.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  // public courses$: Observable<ClassModel[]>;
  public courses$: any;
  public username: any;
  constructor(private apiService: ApiService, private dataservice: DataService, private router: Router) {
    this.username = sessionStorage.getItem('username');
  }

  ngOnInit() {
    this.courses$ = this.apiService.getAvailableClasses();
    this.apiService.getAvailableClasses().subscribe(data => {
      this.courses$ = data[0]['course']
      console.log(data[0]['course'])
    })
    console.log(this.courses$)
  }

  addcourse() {
    this.dataservice.addcourse();
  }

  removeCourse(groupid) {
    this.apiService.removeCourse(groupid).subscribe(
      data => {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
      },
      error => {
        console.log(error)
      }
    )
  }
}
