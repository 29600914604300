<main class="rectangle">
    <p class="cuos-tile"><span class="user-profile-icon">
            <img src="../../../assets/image/main/fluent_hat-graduation-20-regular.png" alt="">
        </span>Your Courses</p>
    <!-- <a class="exit" (click)="addcourse()"> ADD COURSE</a> -->
    <div class="each-course" *ngFor="let course of courses$">
        <div
            class="checkma d-flex flex-row justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xm-end  align-content-lg-start check-mar">
            <img src="../../../assets/image/che.png" alt="" class="chec">
        </div>
        <div class="row">
            <div class="col-md col-xl">
                <div class="panel panel-default">
                    <div class="panel-body">
                        <div class="row">
                            <input type="text" value="{{ course.name }}" disabled id="couses">
                            <ul class="nav nav-pills cosd pt-4 pb-4">
                                <li *ngIf="course.topics[0].length as l" class="course_video">Course Videos: <strong style="color: #CC4174;">{{ l }}
                                    </strong> </li>
                                <li class="cours_date">Course dates: <strong style="color: #CC4174;">{{ course.start_date }} - {{
                                        course.end_date
                                        }}</strong></li>
                            </ul>
                            <div class="text">
                                <a class="classroom" [routerLink]="['/classroom',course.id]">Go to Class</a>
                                <a class="classroom" style="color: #D8D8D8;" (click)="removeCourse(course.id)">Remove Course</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>