import { ActivatedRoute,  Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MaterializeModule } from 'angular2-materialize';
import { ApiService } from "../share/api.service";
import { Location } from "@angular/common";
import { Observable} from "rxjs/index";
import { ClassModel } from "../share/class-model";
import { GroupMember } from "../share/group-member";
import { UserModel } from "../share/user-model";
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import {WebsocketService} from '../_websocket/reconnecting-websocket';
//import ReconnectingWebSocket from '../_websocket/reconnecting-websocket';
@Component({
  selector: 'app-course',
  templateUrl: './course.component2.html',
  styleUrls: ['./course.component.css'],
  providers: [MaterializeModule],
})
export class CourseComponent implements OnInit {
  items: ClassModel[];
  course: ClassModel;
  topicsSub: any;
  classes: Observable<ClassModel[]>;
  topics = [];
  public selectedTopic: number = 0;
  username: string;
  public selectedGroup: number = 0;
  currentUrl: string;
  groupId: string = '0';
  public user = new UserModel();
  token: string = '';
  showstudenta: boolean = false;

  public sessionstatus: string;
  //public websocket: ReconnectingWebSocket;
  //public groupMembers$: Observable<GroupMember[]>;
  public groupMembers$
  public noOfUsers$: Observable<any>;
  public group$: Observable<ClassModel>;
  public is_instructor: boolean;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    public dataservice: DataService,
    private router: Router,
    private location: Location,
    private httpClient: HttpClient,
  ) {
    this.username = sessionStorage.getItem('username');
    this.currentUrl = ''
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.apiService.getAvailableClasses().subscribe(
        data =>{
          // console.log(data)
        },
        error => {
          console.error(error)
        }
      );
      this.getuserProfile()
      const selectedClass = + params["group_id"];
      this.groupId = params["group_id"]
      sessionStorage.setItem('active_group', selectedClass.toString());
      this.group$ = this.apiService.getGroupInfo(sessionStorage.getItem('active_group'))
      // this.group$.subscribe((response: any) =>{
      // }
      //)
      this.selectedGroup = selectedClass;
      if (selectedClass) {
        this.apiService.LoadData(selectedClass);
        this.topicsSub = this.apiService._allTopics$;
        this.noOfUsers$ = this.apiService.getMembers(selectedClass);
      }

    })
    this.route.queryParams.subscribe(queryParam => {
      this.token = queryParam.hash;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });

    // this.groupMembers$ = this.apiService.getGroupMembers(
    //   sessionStorage.getItem('active_group')
    // );
    // this.is_instructor = this.dataservice.isInstructor(
    // );


    // this.websocket = new ReconnectingWebSocket(environment.WS_URL)
    // this.websocket.onopen = (evt) => {
    //   this.sessionstatus = "active"
    // };
    // this.websocket.onclose = (evt) => {
    //   this.sessionstatus = "inactive"
    // }
    this.getgroupmembets()
    window.addEventListener('online', () => {
      this.sessionstatus = "active"
    })
    window.addEventListener('offline', () => {
      this.sessionstatus = "inactive"
    })
  }

  getgroupmembets(){
    //this.groupMembers$ = 
    this.apiService.getClassMembers(sessionStorage.getItem('active_group')).subscribe((data) => {
      this.groupMembers$ = data
    },
    (error) => {
      console.error(error)
    }
    
    );

  }

  ngAfterViewInit() {
    if (!this.selectedTopic) {
      this.topicsSub.subscribe(topics => {
        sessionStorage.setItem('active_topic', topics[0].id);
        this.selectedTopic = topics[0].id;
        this.apiService.setActiveTopic(topics[0].id);
        this.currentUrl = '/classroom/' + this.selectedGroup + '/topic/' + topics[0].id;
      });
    }
  }
  async getuserProfile(){
    this.apiService.getUserInfo(
      sessionStorage.getItem('user_id')
    ).subscribe(data => {
      this.user.username = data['username'];
      this.user.first_name = data['first_name']
      this.user.last_name = data['last_name']
      this.user.profile_img = data['profile_img']
      this.user.id = data['id']
    },
    error => {
      console.log(error)
    });
  }
  public setTopic(id) {
    sessionStorage.setItem('active_topic', id);
    this.selectedTopic = id;
    this.apiService.setActiveTopic(id);
    this.goToVideo();
  }

  filterItem(item: any[], value: string): void {
    if (!item) {
    }
    if (value == "") {
      this.getgroupmembets()
    } else {
      console.log(this.groupMembers$)
      this.groupMembers$ = item.filter((a) =>
        a.user.first_name.toLowerCase().startsWith(value.toLowerCase())
      );
    }
  }
  countUsers() {
    // this.groupMembers$.subscribe(d => {
    // })
    return 0;
  }
showstudent(a:boolean){
  this.showstudenta = a;
}
  goToVideo(end = '') {
    this.apiService.setActiveTopic(this.selectedTopic);
    this.router.navigate(['/classroom/' + this.selectedGroup + '/topic/' + this.selectedTopic + end])
  }

  goToLab(id) {
    this.apiService.setActiveTopic(this.selectedTopic);
    this.router.navigate(['/classroom/' + this.selectedGroup + '/topic/' + this.selectedTopic + '/lab'])
  }

  goToNote() {
    this.apiService.setActiveTopic(this.selectedTopic);
    this.router.navigate(['/classroom/' + this.selectedGroup + '/topic/' + this.selectedTopic + '/notes'])
  }

  goToChat() {
    this.router.navigate([this.location.path() + 'topic/' + this.selectedTopic + '/chat'])
  }

  getTopic(id: number) {
    return this.topics.find(
      (data) => {
        return data.id === id;
      }
    );
  }

  logout() {
    this.dataservice.logout();
  }
  async getRoomid(id:number){
    this.apiService.getPivateRoom(id).subscribe(
      data => {
        this.router.navigate(['/classroom/' + this.selectedGroup + '/main/' + data])
      },
      error => {
        console.log(error)
      }
    )
  }
  tabToVideo(id) {
    sessionStorage.setItem('active_topic', id);
    this.selectedTopic = id;
    this.apiService.setActiveTopic(id);
    this.goToVideo('/video');
  }

  isCureentTab(tab) {
    return this.currentUrl.indexOf(tab) !== -1;
  }

  isCurrentRoute(topic_id): boolean {
    const path = '/classroom/' + this.selectedGroup + '/topic/' + topic_id;
    return path === this.currentUrl ||
      path + '/video' === this.currentUrl ||
      path + '/lab' === this.currentUrl ||
      path + '/notes' === this.currentUrl;
  }

}



