<div class="main-pg">
  <div class="main-top" fxLayout="row">
    <div class="main-top-left">
      <div class="main-welcome">
        Welcome Back
      </div>
      <div class="main-latest">
        YOUR LATEST ACTIVITY
      </div>
      <div class="main-django">
        Django Group Course
      </div>
      <div class="main-description">
        Diam, odio aliquet iaculis nisi, est urna diam etiam suspendisse. Pretium vel tellus neque faucibus tincidunt.
      </div>
      <div class="main-progress">
        20% (in progress)
      </div>
      <button class="main-top-btn" fxLayout="row" fxLayoutAlign="center center" mat-raised-button><div>Continue</div></button>
    </div>
    <div class="main-top-right"></div>
  </div>
  <div class="course-content">
    <div fxLayout="row wrap">
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
        <app-course></app-course>
      </div>
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
        <app-course></app-course>
      </div>
    </div>
  </div>
</div>
