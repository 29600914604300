import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClassroomRoutingModule} from './classroom-routing.module';
import { MainClassroomComponent } from './main-classroom/main-classroom.component';
import {ClassroomComponent} from './classroom.component';
import { NavClassroomComponent } from './nav-classroom/nav-classroom.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import { CourseComponent } from './components/course/course.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    ClassroomRoutingModule,
    FlexLayoutModule,
    MatButtonModule,
    MatProgressBarModule
  ],
  declarations: [
    ClassroomComponent,
    MainClassroomComponent,
    NavClassroomComponent,
    CourseComponent
  ]
})
export class ClassroomModule { }
