import { Component, Directive, OnInit, ElementRef, Input, Output, EventEmitter, ViewContainerRef, TemplateRef, ViewChild  } from '@angular/core';
import { ChatService } from './../../share/chat.service';
import { ApiService } from './../../share/api.service'
import { MenuContextualService } from 'src/app/share/menu-contextual.service';
import { MainChatComponent } from '../main-chat/main-chat.component';
import { WebsocketService } from 'src/app/_websocket/reconnecting-websocket';
@Component({
  selector: 'app-others-chat',
  templateUrl: './others-chat.component.html',
  styleUrls: ['./others-chat.component.css'],
})
// @Directive({
//     selector: '[emoji]'
//   })

export class OthersChatComponent implements OnInit {
    public current_user:string;
    @Output() qouted = new EventEmitter();
    @Input() message;
    @ViewChild('emojimenu', {static: true}) emojimenu: TemplateRef<any>; 
    // = [{id: 10, icon: "0x1F600" }, {id: 20, icon: "0x1F604" }, {id: 30, icon: "0x1F34A"}];
    public emojilist = ['0x1F600', '0x1F604', '0x1F34A', '0x1F344', '0x1F37F', '0x1F363', '0x1F370', '0x1F355',
        '0x1F354', '0x1F35F', '0x1F6C0', '0x1F48E', '0x1F5FA', '0x23F0', '0x1F579', '0x1F4DA',
        '0x1F431', '0x1F42A', '0x1F439', '0x1F424'];
    @Input('emoji') emoji: string;
    constructor(private WebsocketService: WebsocketService, public nmain: MainChatComponent, private apiservice: ApiService, private el: ElementRef, private popupemoji: MenuContextualService, private viewContainerRef: ViewContainerRef, public chatService:ChatService, private apiService:ApiService){
        this.current_user = sessionStorage.getItem('username');
        
    }

    ngOnInit(): void {
        //This is empty
    }   
    openemoji({x, y}:MouseEvent, emojimenu, members) {
        this.popupemoji.open({x, y}, this.emojimenu, this.viewContainerRef, members).subscribe(res => {
            console.log(res)
        })
    }
    updateemoji(data, emoji){
        console.log(data)
        this.apiService.upateMessages(data.id, {"reaction": emoji}).subscribe(
            data => {
                console.log(data)
            },
            error => {
                console.error(data)
        })

        // Send to websocket
        let payload = {
            source: '',
            content: '',
            messages: '',
        };
        let context = {
            action: 'sendMessage',
            active_group:  sessionStorage.getItem('active_group'),
            user: sessionStorage.getItem('username'),
            user_profile_img: data['profile_img'],
            content: data['content'],
            the_type: data["type"],
            timestamp: data['timestamp'],
            token:  sessionStorage.getItem('token'),
            reaction: emoji,
            id: data['id'],
            private: false,
        };
        payload.source = 'localhost';
        payload.content = JSON.stringify(context);
        
        this.WebsocketService.messages.next(payload);
        this.popupemoji.close(null)
    }
    isOthers(username:string){
        return username !== this.current_user && 
        username !== 'DATE-INFO'
    }
    getreaction(emoji): any{
        let em = null
        em = String.fromCodePoint(emoji)
        return em
    }

    qoute(){
        this.qouted.emit(this.message)
    }
    
    downloadImage(url){
            let urlArray = url.split("/")
            let bucket = urlArray[3]
            let key = `${urlArray[4]}/${urlArray[5]}`
            console.log(key)
            console.log(bucket)
            console.log(urlArray)

            this.apiService.getfiletoDownload(url).subscribe(
                data => {
                    console.log(data)
                },
                error =>console.log(error)
            )
          }
    }
